import React from "react";

import ControlTicketForm from "./ControlTicketForm";

import { addGeneralCommand, sendAction } from "../../helpers/Assistant";
import { AssistantContext } from "../../helpers/AssistantContext";
import { detectDeviceSize } from "../../helpers/ScreenUtils";
import { setPageCommands } from "../../helpers/Assistant";

import {
  ALLOWED_VARIABLES_4x20,
  MAIN_LOTTO_RULES,
  ALLOWED_VARIABLES_DUEL,
} from "../constants";
import { getRandomSetOfNumbers, getScreenMode } from "./utils";

class ScreenLotto extends React.Component {
  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    this.state = {
      gameName: this.props.gameInfo.name,
      tickets: this.props?.selectedTicketNumbers || [
        {
          key: 0,
          selectedNumbersFirstTicket: new Set(),
          selectedNumbersSecondTicket: new Set(),
        },
        {
          key: 1,
          selectedNumbersFirstTicket: new Set(),
          selectedNumbersSecondTicket: new Set(),
        },
        {
          key: 2,
          selectedNumbersFirstTicket: new Set(),
          selectedNumbersSecondTicket: new Set(),
        },
      ],
      withSelectorControl: this.props?.selectedTicketNumbers || true,
      selectedTicketKey: 0,
      gameInfo: this.props.gameInfo,
      sumToPay: 0,
      screenMode: getScreenMode(),
      ticketQty: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gameInfo.name !== this.props.gameInfo.name) {
      // let carouselBox = document.getElementById('styled_carousel_Box')
      // if(carouselBox){
      //     document.getElementById('styled_carousel_Box').remove()
      // }
      this.setState({
        tickets: [
          {
            key: 0,
            selectedNumbersFirstTicket: new Set(),
            selectedNumbersSecondTicket: new Set(),
          },
          {
            key: 1,
            selectedNumbersFirstTicket: new Set(),
            selectedNumbersSecondTicket: new Set(),
          },
          {
            key: 2,
            selectedNumbersFirstTicket: new Set(),
            selectedNumbersSecondTicket: new Set(),
          },
          {
            key: 3,
            selectedNumbersFirstTicket: new Set(),
            selectedNumbersSecondTicket: new Set(),
          },
          {
            key: 4,
            selectedNumbersFirstTicket: new Set(),
            selectedNumbersSecondTicket: new Set(),
          },
        ],
      });
    }
  }
  componentDidMount() {
    addGeneralCommand(this.context, "DRAW_CHANGE", (action) => {
      this.setState(action.payload);
    });
  }
  componentWillUnmount() {
    setPageCommands(this.context, {});
  }

  updateGame = (payload) => {
    this.setState({
      tickets: [
        {
          key: 0,
          selectedNumbersFirstTicket: new Set(),
          selectedNumbersSecondTicket: new Set(),
          ticketSecondVoiceBet: false,
          ticketWasMaximum: false,
        },
      ],
      gameName: payload.name,
      gameInfo: payload,
    });
  };

  getCountDefaultTickets() {
    const ticketsCount = [
      "6x45",
      "7x49",
      "4x20",
      "oxota",
      "5x36plus",
      "5x2",
    ].includes(this.props.gameInfo.name)
      ? 1
      : [
          "rapido",
          "rapido2",
          "rapido-drive",
          "rapido-ultra",
          "1224",
          "duel",
          "zabava",
          "dvazhdydva",
        ].includes(this.props.gameInfo.name)
      ? 1
      : null;
    return ticketsCount;
  }

  getDefaultTickets() {
    const tickets = Array(this.getCountDefaultTickets())
      .fill()
      .map((_, i) => ({
        key: i,
        selectedNumbersFirstTicket: new Set(),
        selectedNumbersSecondTicket: new Set(),
        ticketSecondVoiceBet: false,
        ticketWasMaximum: false,
      }));
    return tickets;
  }

  addNewTicket = () => {
    let tickets = this.state.tickets;
    tickets.push({
      key: tickets.length,
      selectedNumbersFirstTicket: new Set(),
      selectedNumbersSecondTicket: new Set(),
      ticketSecondVoiceBet: false,
      ticketWasMaximum: false,
    });

    this.setState({ tickets: tickets });
  };

  selectTicket = (value) => {
    this.setState({ selectedTicketKey: value + 1 });
  };

  setRandomNumbers = (ticketKey, gameName) => {
    console.log(ticketKey, gameName);
    let tickets = this.state.tickets;
    const firstTicketRestrictionsMin =
      MAIN_LOTTO_RULES[gameName]["firstTicket"]["restrictions"]["min"];
    const maxSize =
      firstTicketRestrictionsMin > 1
        ? firstTicketRestrictionsMin - 1
        : firstTicketRestrictionsMin;

    if (MAIN_LOTTO_RULES[gameName].secondTicket) {
      const secondTicketRestrictionsMin =
        MAIN_LOTTO_RULES[gameName]["secondTicket"]["restrictions"]["min"];
      const maxSize =
        secondTicketRestrictionsMin > 1 ? secondTicketRestrictionsMin - 1 : 0;
      console.log(maxSize);
      console.log(
        MAIN_LOTTO_RULES[gameName]["secondTicket"][detectDeviceSize()][
          "maxSquareViewed"
        ]
      );
      tickets[ticketKey].selectedNumbersSecondTicket = getRandomSetOfNumbers(
        maxSize,
        MAIN_LOTTO_RULES[gameName]["secondTicket"][detectDeviceSize()][
          "maxSquareViewed"
        ] + 1
      );
    }
    console.log(maxSize);
    console.log(
      MAIN_LOTTO_RULES[gameName]["firstTicket"][detectDeviceSize()][
        "maxSquareViewed"
      ]
    );
    tickets[ticketKey].selectedNumbersFirstTicket = getRandomSetOfNumbers(
      maxSize,
      MAIN_LOTTO_RULES[gameName]["firstTicket"][detectDeviceSize()][
        "maxSquareViewed"
      ] + 1
    );
    const ticketInfoPay = this.getPaySum(tickets);

    const sumToPay = ticketInfoPay["sumToPay"];
    const ticketQty = ticketInfoPay["ticketQty"];
    this.setState({
      tickets: tickets,
      sumToPay: sumToPay,
      ticketQty: ticketQty,
    });
  };

  setTicketNumber = (currentNumber, ticketNumber, ticketStatus) => {
    const tickets = this.state.tickets;
    for (let ticket in tickets) {
      let currentTicket = tickets[ticket];
      if (currentTicket.key == ticketNumber) {
        const targetGame =
          ticketStatus === "mainTicket"
            ? "selectedNumbersFirstTicket"
            : "selectedNumbersSecondTicket";
        const targetTicket =
          ticketStatus === "mainTicket" ? "firstTicket" : "secondTicket";
        if (currentTicket[targetGame].delete(currentNumber)) {
          break;
        }
        if (["4x20", "oxota"].includes(this.props.gameInfo.name)) {
          if (
            this.specialGameChecker(
              ALLOWED_VARIABLES_4x20,
              currentTicket,
              targetGame
            )
          ) {
            currentTicket[targetGame].add(currentNumber);
          }
          break;
        }
        if (["duel", "dvazhdydva"].includes(this.props.gameInfo.name)) {
          if (
            this.specialGameChecker(
              ALLOWED_VARIABLES_DUEL,
              currentTicket,
              targetGame
            )
          ) {
            currentTicket[targetGame].add(currentNumber);
          }
          break;
        }
        if (
          currentTicket[targetGame].size <
          MAIN_LOTTO_RULES[this.props.gameInfo.name][targetTicket].restrictions
            .max
        ) {
          currentTicket[targetGame].add(currentNumber);
        }
      }
    }
    const ticketInfoPay = this.getPaySum(tickets);
    const sumToPay = ticketInfoPay["sumToPay"];
    const ticketQty = ticketInfoPay["ticketQty"];

    this.setState({
      tickets: ticketInfoPay["tickets"],
      sumToPay: sumToPay,
      ticketQty: ticketQty,
    });
  };

  sendMultiplyBet() {
    if (
      [
        "6x45",
        "7x49",
        "rapido2",
        "rapido",
        "rapido-drive",
        "rapido-ultra",
        "5x36",
        "4x20",
        "oxota",
      ].includes(this.props.gameInfo.name)
    ) {
      sendAction(this.context, { action_id: "MULTIPLE_BET", parameters: {} });
    }
  }
  getPaySum(tickets) {
    let sumToPay = 0;
    let ticketQty = 0;
    for (let ticket in tickets) {
      let sizeMap = "";
      let inversedSizeMap = "";
      if (MAIN_LOTTO_RULES[this.props.gameInfo.name]["secondTicket"]) {
        sizeMap =
          this.state.tickets[ticket]["selectedNumbersFirstTicket"].size +
          "_" +
          this.state.tickets[ticket]["selectedNumbersSecondTicket"].size;
        inversedSizeMap =
          this.state.tickets[ticket]["selectedNumbersSecondTicket"].size +
          "_" +
          this.state.tickets[ticket]["selectedNumbersFirstTicket"].size;
      } else {
        sizeMap = tickets[ticket]["selectedNumbersFirstTicket"].size;
      }
      let multyply = 0;

      multyply =
        MAIN_LOTTO_RULES[this.props.gameInfo.name]["multipleerTable"]?.[
          sizeMap
        ] ||
        MAIN_LOTTO_RULES[this.props.gameInfo.name]["multipleerTable"]?.[
          inversedSizeMap
        ] ||
        0;
      sumToPay += multyply * this.state.gameInfo.draw.betCost;

      if (multyply > 0) {
        ticketQty += 1;
      }
      if (
        multyply >= this.getSecondPlaceMultiply() &&
        !tickets[ticket].ticketSecondVoiceBet
      ) {
        this.sendMultiplyBet();
        tickets[ticket].ticketSecondVoiceBet = true;
        tickets[ticket].ticketWasMaximum = true;
      }
      if (multyply > 0 && !tickets[ticket].ticketWasMaximum) {
        tickets[ticket].ticketWasMaximum = true;
      }
    }

    return { sumToPay: sumToPay, ticketQty: ticketQty, tickets: tickets };
  }
  getSecondPlaceMultiply() {
    let dictionary =
      MAIN_LOTTO_RULES[this.props.gameInfo.name]["multipleerTable"];
    const values = Object.keys(dictionary).map(function (key) {
      return dictionary[key];
    });
    return Math.min.apply(
      Math,
      values.filter((value) => value > 1)
    );
  }
  specialGameChecker(checkingArray, currentTicket, targetGame) {
    const firstTicketAdd = targetGame === "selectedNumbersFirstTicket" ? 1 : 0;
    const secondTicketAdd =
      targetGame === "selectedNumbersSecondTicket" ? 1 : 0;

    const newSizeFirstTicket =
      currentTicket["selectedNumbersFirstTicket"].size + firstTicketAdd;
    const newSizeSecondTicket =
      currentTicket["selectedNumbersSecondTicket"].size + secondTicketAdd;

    const sizeMap = newSizeFirstTicket + "_" + newSizeSecondTicket;
    const inversedSizeMap = newSizeSecondTicket + "_" + newSizeFirstTicket;
    return (
      checkingArray.includes(sizeMap) || checkingArray.includes(inversedSizeMap)
    );
  }

  removeTicket = (ticketId) => {
    let currentTickets = this.state.tickets;
    if (currentTickets.length > 1) {
      currentTickets.splice(ticketId, 1);
      this.setState({ tickets: currentTickets });
    }
  };

  clearTicket = (ticketId) => {
    let currentTickets = this.state.tickets;
    currentTickets[ticketId].selectedNumbersFirstTicket = new Set();
    currentTickets[ticketId].selectedNumbersSecondTicket = new Set();
    const ticketInfoPay = this.getPaySum(currentTickets);
    const sumToPay = ticketInfoPay["sumToPay"];
    const ticketQty = ticketInfoPay["ticketQty"];
    this.setState({
      tickets: currentTickets,
      sumToPay: sumToPay,
      ticketQty: ticketQty,
    });
  };

  render() {
    const secondTicket = MAIN_LOTTO_RULES[this.props.gameInfo.name].secondTicket
      ? MAIN_LOTTO_RULES[this.props.gameInfo.name].secondTicket[
          detectDeviceSize()
        ].maxSquareViewed
      : null;

    return (
      <>
        <ControlTicketForm
          messagePopupWasOpened={this.props.messagePopupWasOpened}
          messagePopupWasOpenedFunction={
            this.props.messagePopupWasOpenedFunction
          }
          withSelectorControl={this.state.withSelectorControl}
          paySum={this.state.sumToPay}
          gameInfo={this.props.gameInfo}
          addNewTicket={this.addNewTicket}
          tickets={this.state.tickets}
          rules={MAIN_LOTTO_RULES[this.props.gameInfo.name]}
          selectedTicketKey={this.state.selectedTicketKey}
          setTicketNumber={this.setTicketNumber}
          gameName={this.props.gameInfo.name}
          setRandomNumbers={this.setRandomNumbers}
          selectTicket={this.selectTicket}
          rowsFirstPlayingField={
            MAIN_LOTTO_RULES[this.props.gameInfo.name]["firstTicket"][
              detectDeviceSize()
            ]["rows"]
          }
          colsFirstPlayingField={
            MAIN_LOTTO_RULES[this.props.gameInfo.name]["firstTicket"][
              detectDeviceSize()
            ]["cols"]
          }
          maxSquareViewedFirstPlayingField={
            MAIN_LOTTO_RULES[this.props.gameInfo.name]["firstTicket"][
              detectDeviceSize()
            ]["maxSquareViewed"]
          }
          rowsSecondPlayingField={
            MAIN_LOTTO_RULES[this.props.gameInfo.name].secondTicket
              ? MAIN_LOTTO_RULES[this.props.gameInfo.name].secondTicket[
                  detectDeviceSize()
                ].rows
              : null
          }
          colsSecondPlayingField={
            MAIN_LOTTO_RULES[this.props.gameInfo.name].secondTicket
              ? MAIN_LOTTO_RULES[this.props.gameInfo.name].secondTicket[
                  detectDeviceSize()
                ].cols
              : null
          }
          maxSquareViewedSecondPlayingField={secondTicket}
          ticketWaiting={this.props?.ticketWaiting}
          ticketQty={this.state.ticketQty}
          removeTicket={this.removeTicket}
          clearTicket={this.clearTicket}
          waitResult={this.props?.ticketWaiting}
          messageShow={this.props.messageShow}
          updateGame={this.updateGame}
        />
      </>
    );
  }
}

export default ScreenLotto;
