import { createGlobalStyle } from "styled-components";
import { darkBrand } from "@sberdevices/plasma-tokens/themes"; // Или один из списка: darkEva, darkJoy, lightEva, lightJoy, lightSber
import {
  text, // Цвет текста
  background, // Цвет подложки
  gradient, // Градиент
} from "@sberdevices/plasma-tokens";

const DocumentStyle = createGlobalStyle`
    html {
        color: ${text};
        background-color: ${background};
        background-image: ${gradient};
    }
`;

const darkBrandGreen = {
  ...darkBrand,
  ":root": {
    ...darkBrand[":root"],
    "--plasma-colors-button-focused": "#12A557",
    "--plasma-colors-surface-card": "#1F1F1F",
    "--plasma-colors-button-success": "#12A557",
    "--plasma-colors-background": "#000000",
  },
};
const ThemeStyle = createGlobalStyle(darkBrandGreen);

export const GlobalStyle = () => (
  <>
    <DocumentStyle />
    <ThemeStyle />
  </>
);
