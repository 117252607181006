import React from "react";

import styled from "styled-components";
import {
  CarouselItem,
  Carousel,
  TextBoxSubTitle,
  TabItem,
  Tabs,
  Col,
  Row,
  TextBox,
  Card,
} from "@sberdevices/plasma-ui";

export const KenoFirstGameTextBoxSubTitle = styled(TextBoxSubTitle)`
  color: white;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 24px;
    //padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 0px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 18px;
    //padding-top: 10px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
    margin-left: 4px;
    margin-top: -1px !important;
  }
`;

export const KenoFirstGameRow = styled(Row)`
  margin-bottom: 0.5em;
  margin-top: 10px;
  @media (min-width: 1281px) and (max-width: 1961px) {
    //margin-left: -24px;
    margin-bottom: 0.6em;
    margin-top: 0px;
    padding-left: 3px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    //margin-left: -17px;
    margin-top: 6px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-left: 3px;
    margin-top: 6px;
    margin-bottom: 14px;
  }
`;

export const KenoFirstGameCol = styled(Col)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-top: 10px;
    padding-left: 0px;
    margin-left: -5px;
  }

  @media (min-width: 961px) and (max-width: 1281px) {
    max-height: 80px;
    margin-left: -5px;

    //padding-bottom: 20px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    //margin-top: -16px;
    //margin-left: 3px;
    height: 55px;
    // padding-top:5px;
  }
`;

export const KenoFirstGameTabItem = styled(TabItem)`
  height: 1.5em;
  color: ${(props) =>
    props.activeTab ? "white" : " rgba(255, 255, 255, 0.56)"};
  background-color: ${(props) => props.activeTab && "rgba(255, 255, 255, 0.2)"};
  background-color: ${(props) =>
    props.waitResult && props.activeTab && "#12A557"};
  font-weight: 400;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 24px;
    padding: 20px;
    height: 2em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 18px;
    padding: 14px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
    padding: 10px;
  }
`;

export const KenoThirdGameStyledCarouselItem = React.memo(styled(CarouselItem)`
  &:last-child {
    padding-right: 0;
  }

  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-right: 26px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-right: 6px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-right: 10px;
  }
`);
export const KenoThirdGameStyledCarousel = styled(Carousel)`
  box-sizing: border-box;
  //padding-top: 8px;
  //padding-bottom: 8px;
  outline: none;
  scroll-snap-type: none;
  scroll-behavior: smooth;
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-bottom: 33px;
    //padding-left: 8px;
    padding-left: 13px;
    padding-top: 13px;
    padding-right: 13px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    max-width: 312px;
    //margin-top: 3em;
  }
`;
export const KenoThirdGameOutlineGreenwrapper = styled(Card)`
  // &:focus {
  //     outline: 4px solid #12A557;
  // }
  // -webkit-border-radius:14px;
  // border-radius: 14px;
`;

export const KenoThirdGameTabs = React.memo(styled(Tabs)`
  display: inline-table;
  background-color: ${(props) =>
    props.activeCol
      ? props.waitResult
        ? "#3B6B3E"
        : "rgba(255, 255, 255, 0.26)"
      : "rgba(255, 255, 255, 0.12)"};

  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 84px;
    height: 84px;
    border-radius: 15px;
    padding-top: 0px;
    //margin-right: -3px !important;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 46px;
    height: 46px;
    border-radius: 15px;
    padding-top: 0px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    width: 10px;
    border-radius: 15px;
    padding-top: 0px;
  }
`);

export const KenoThirdGameTabItem = styled(TabItem)`
  display: table-cell;
  color: ${(props) => (props.activeCol ? "black" : "none")};
  background-color: ${(props) =>
    props.activeCol
      ? props.waitResult
        ? "#60A657"
        : "white"
      : props.isFirstTabInCol
      ? "rgba(255, 255, 255, 0.12)"
      : "none"};
  background-color: ${(props) =>
    props.waitResult && props.activeCol && "#12A557"};
  font-weight: 400;
  margin-right: 0px;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 28px;
    width: 84px;
    height: ${(props) => (props.isFirstTabInCol ? "84px" : "65px")};
    margin-left: 0px !important;
    margin-right: 0px !important;
    border-radius: 16px;
    padding: 0px;
    //padding-bottom: 1px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 24px;
    padding: 0px;
    width: 60px;
    height: ${(props) => (props.isFirstTabInCol ? "45px" : "58px")};
    margin-left: 0px !important;
    margin-right: 0px !important;
    border-radius: 15px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 15px;

    width: 46px;
    height: 39px;
    border-radius: 12px;
  }
`;

export const KenoThirdGameTabItemMobileListCols = styled(KenoThirdGameTabItem)`
  display: table-cell;
  color: ${(props) => (props.waitResult ? "white" : "black")};
  background-color: ${(props) => (props.waitResult ? "#12A557" : "white")};

  font-weight: 400;
  margin-right: 0px;
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 15px;
    width: 80px;
    height: 24px;
    border-radius: 15px;
    padding: 2px;
    font-size: 12px;
  }
`;

export const KenoThirdGameTextBoxRule = styled(TextBox)`
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 44px;
  }
`;

export const KenoThirdGameTextBoxSubTitle = styled(TextBoxSubTitle)`
  color: white;
  //margin-top: 1.5em;
  //margin-bottom: 0.5em;

  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 24px;
    padding-top: 19px;
    //padding-left: 8px;
    //padding-bottom: 20px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 18px;
    padding-top: 20px;
    height: 40px;
    // padding-bottom: 30px;
  }

  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
    max-width: 240px;
    padding-left: 4px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

export const KenoThirdGameTextBoxSubTitleMobile = styled(
  KenoThirdGameTextBoxSubTitle
)`
  color: white;
  padding: 0px;
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
    // padding-top: 13px;
    padding-bottom: 10px;
  }
`;
