"use strict";
// Generated by robots, do not change this manually!
Object.defineProperty(exports, "__esModule", { value: true });
exports.darkJoy = void 0;
exports.darkJoy = {
    ":root": {
        "--plasma-colors-white": "#FFFFFF",
        "--plasma-colors-whitePrimary": "#FFFFFF",
        "--plasma-colors-whiteSecondary": "rgba(255, 255, 255, 0.56)",
        "--plasma-colors-whiteTertiary": "rgba(255, 255, 255, 0.28)",
        "--plasma-colors-black": "#080808",
        "--plasma-colors-blackPrimary": "#080808",
        "--plasma-colors-blackSecondary": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-blackTertiary": "rgba(8, 8, 8, 0.28)",
        "--plasma-colors-dark01": "#171717",
        "--plasma-colors-dark02": "#232323",
        "--plasma-colors-dark03": "#363636",
        "--plasma-colors-transparent": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonClear": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonBlack": "#080808",
        "--plasma-colors-buttonBlackSecondary": "rgba(8, 8, 8, 0.12)",
        "--plasma-colors-buttonBlackTransparent": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-buttonWhite": "#FFFFFF",
        "--plasma-colors-buttonWhiteSecondary": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-text": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-primary": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-secondary": "rgba(255, 255, 255, 0.56)",
        "--plasma-colors-tertiary": "rgba(255, 255, 255, 0.28)",
        "--plasma-colors-paragraph": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-inverse": "#080808",
        "--plasma-colors-background": "#080808",
        "--plasma-colors-backgroundPrimary": "#171717",
        "--plasma-colors-backgroundSecondary": "#232323",
        "--plasma-colors-backgroundTertiary": "#363636",
        "--plasma-colors-success": "#24B23E",
        "--plasma-colors-warning": "#FA6D20",
        "--plasma-colors-critical": "#FF4D5F",
        "--plasma-colors-overlay": "rgba(0, 0, 0, 0.8)",
        "--plasma-colors-surfaceLiquid01": "rgba(255, 255, 255, 0.06)",
        "--plasma-colors-surfaceLiquid02": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-surfaceLiquid03": "rgba(255, 255, 255, 0.2)",
        "--plasma-colors-surfaceSolid01": "#171717",
        "--plasma-colors-surfaceSolid02": "#232323",
        "--plasma-colors-surfaceSolid03": "#363636",
        "--plasma-colors-surfaceCard": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-buttonPrimary": "#FFFFFF",
        "--plasma-colors-buttonSecondary": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-buttonSuccess": "#21A038",
        "--plasma-colors-buttonWarning": "#E35502",
        "--plasma-colors-buttonCritical": "#FF2E43",
        "--plasma-colors-buttonChecked": "#FFFFFF",
        "--plasma-colors-skeletonGradient": "linear-gradient( 90deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.08) 6.25%, rgba(255, 255, 255, 0.05) 12.5%, rgba(255, 255, 255, 0.01) 25%, rgba(255, 255, 255, 0.05) 37.5%, rgba(255, 255, 255, 0.08) 43.75%, rgba(255, 255, 255, 0.09) 50%, rgba(255, 255, 255, 0.08) 56.25%, rgba(255, 255, 255, 0.05) 62.5%, rgba(255, 255, 255, 0.01) 75%, rgba(255, 255, 255, 0.05) 87.5%, rgba(255, 255, 255, 0.08) 93.75%, rgba(255, 255, 255, 0.09) 100% )",
        "--plasma-colors-skeletonGradientLighter": "linear-gradient( 90deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.32) 6.25%, rgba(255, 255, 255, 0.20) 12.5%, rgba(255, 255, 255, 0.04) 25%, rgba(255, 255, 255, 0.20) 37.5%, rgba(255, 255, 255, 0.32) 43.75%, rgba(255, 255, 255, 0.36) 50%, rgba(255, 255, 255, 0.08) 56.25%, rgba(255, 255, 255, 0.20) 62.5%, rgba(255, 255, 255, 0.04) 75%, rgba(255, 255, 255, 0.20) 87.5%, rgba(255, 255, 255, 0.32) 93.75%, rgba(255, 255, 255, 0.36) 100% )",
        "--plasma-colors-speechBubbleSent": "rgba(0, 0, 0, 0.28)",
        "--plasma-colors-speechBubbleReceived": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-accent": "#C370FA",
        "--plasma-colors-buttonAccent": "#B559F3",
        "--plasma-colors-buttonFocused": "#B559F3",
        "--plasma-colors-gradient": "linear-gradient(336.9deg, rgba(255, 156, 101, 0.24) 0%, rgba(8, 8, 8, 0) 64.95%), radial-gradient(66.53% 100% at 73.33% 100%, rgba(61, 19, 149, 0.34) 0%, rgba(19, 24, 149, 0.1) 99.69%), linear-gradient(180.03deg, rgba(8, 8, 8, 0) 50%, rgba(255, 215, 179, 0.15) 99.97%), linear-gradient(270deg, rgba(107, 15, 87, 0.2) 0%, rgba(8, 8, 8, 0) 100%)",
        "--plasma-colors-gradientDevice": "linear-gradient(26.05deg, rgba(26, 140, 255, 0.16) 0%, rgba(8, 8, 8, 0) 72.24%), radial-gradient(100% 100% at 0% 100%, rgba(0, 85, 255, 0.28) 0%, rgba(8, 8, 8, 0) 99.69%), radial-gradient(74.68% 149.35% at 50% 149.35%, rgba(128, 0, 255, 0.48) 0%, rgba(8, 8, 8, 0) 99.69%)",
        "--plasma-colors-voicePhraseGradient": "linear-gradient(45deg, hsla(19.862068965517242, 100%, 71.56862745098039%, 1) 0%, hsla(319.8058252427184, 94.49541284403672%, 78.62745098039217%, 1) 100%)",
        "color": "rgba(255, 255, 255, 0.96)",
        "backgroundColor": "#080808"
    }
};
