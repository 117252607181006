// Generated by robots, do not change this manually!
/** Базовый белый цвет совпадает с whitePrimary */
export var white = 'var(--plasma-colors-white, #FFFFFF)';
/** Первичный белый цвет */
export var whitePrimary = 'var(--plasma-colors-white-primary, #FFFFFF)';
/** Вторичный белый цвет */
export var whiteSecondary = 'var(--plasma-colors-white-secondary, rgba(255, 255, 255, 0.56))';
/** Третичный белый цвет */
export var whiteTertiary = 'var(--plasma-colors-white-tertiary, rgba(255, 255, 255, 0.28))';
/** Базовый черный цвет совпадает с blackPrimary */
export var black = 'var(--plasma-colors-black, #080808)';
/** Первичный черный цвет */
export var blackPrimary = 'var(--plasma-colors-black-primary, #080808)';
/** Вторичный черныйцвет */
export var blackSecondary = 'var(--plasma-colors-black-secondary, rgba(8, 8, 8, 0.56))';
/** Третичный черный цвет */
export var blackTertiary = 'var(--plasma-colors-black-tertiary, rgba(8, 8, 8, 0.28))';
/** Чёрный непрозрачный, не зависит от темы */
export var dark01 = 'var(--plasma-colors-dark01, #171717)';
/** Чёрный непрозрачный, не зависит от темы */
export var dark02 = 'var(--plasma-colors-dark02, #232323)';
/** Чёрный непрозрачный, не зависит от темы */
export var dark03 = 'var(--plasma-colors-dark03, #363636)';
/** Прозрачный цвет */
export var transparent = 'var(--plasma-colors-transparent, rgba(0, 0, 0, 0))';
/** Второстепенная прозрачная */
export var buttonClear = 'var(--plasma-colors-button-clear, rgba(0, 0, 0, 0))';
/** Дефолтная чёрная, не зависит от темы */
export var buttonBlack = 'var(--plasma-colors-button-black, #080808)';
/** Второстепенная чёрная, не зависит от темы */
export var buttonBlackSecondary = 'var(--plasma-colors-button-black-secondary, rgba(8, 8, 8, 0.12))';
/** Чёрная для использования поверх картинок */
export var buttonBlackTransparent = 'var(--plasma-colors-button-black-transparent, rgba(8, 8, 8, 0.56))';
/** Дефолтная белая, не зависит от темы */
export var buttonWhite = 'var(--plasma-colors-button-white, #FFFFFF)';
/** Второстепенная белая, не зависит от темы */
export var buttonWhiteSecondary = 'var(--plasma-colors-button-white-secondary, rgba(255, 255, 255, 0.12))';
/** Базовый цвет текста, совпадает с primary */
export var text = 'var(--plasma-colors-text, rgba(255, 255, 255, 0.96))';
/** Основной текст в интерфейсе */
export var primary = 'var(--plasma-colors-primary, rgba(255, 255, 255, 0.96))';
/** Второстепенный текст */
export var secondary = 'var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))';
/** Третичный цвет текста */
export var tertiary = 'var(--plasma-colors-tertiary, rgba(255, 255, 255, 0.28))';
/** Сплошной наборный текст */
export var paragraph = 'var(--plasma-colors-paragraph, rgba(255, 255, 255, 0.96))';
/** Белый в светлой теме / Чёрный в тёмной теме */
export var inverse = 'var(--plasma-colors-inverse, #080808)';
/** Основной бэкграунд всех приложений и смартапов */
export var background = 'var(--plasma-colors-background, #080808)';
/** Первичный цвет фона */
export var backgroundPrimary = 'var(--plasma-colors-background-primary, #171717)';
/** Вторичный цвет фона */
export var backgroundSecondary = 'var(--plasma-colors-background-secondary, #232323)';
/** Третичный цвет фона */
export var backgroundTertiary = 'var(--plasma-colors-background-tertiary, #363636)';
/** Обозначение успешного сценария */
export var success = 'var(--plasma-colors-success, #24B23E)';
/** Цвет предупреждения */
export var warning = 'var(--plasma-colors-warning, #FA6D20)';
/** Цвет ошибки */
export var critical = 'var(--plasma-colors-critical, #FF4D5F)';
/** Цвет фона паранжи */
export var overlay = 'var(--plasma-colors-overlay, rgba(0, 0, 0, 0.8))';
/** Полупрозрачные поверхности */
export var surfaceLiquid01 = 'var(--plasma-colors-surface-liquid01, rgba(255, 255, 255, 0.06))';
/** Полупрозрачные поверхности */
export var surfaceLiquid02 = 'var(--plasma-colors-surface-liquid02, rgba(255, 255, 255, 0.12))';
/** Полупрозрачные поверхности */
export var surfaceLiquid03 = 'var(--plasma-colors-surface-liquid03, rgba(255, 255, 255, 0.2))';
/** Непрозрачные поверхности */
export var surfaceSolid01 = 'var(--plasma-colors-surface-solid01, #171717)';
/** Непрозрачные поверхности */
export var surfaceSolid02 = 'var(--plasma-colors-surface-solid02, #232323)';
/** Непрозрачные поверхности */
export var surfaceSolid03 = 'var(--plasma-colors-surface-solid03, #363636)';
/** Основной фон для карточек */
export var surfaceCard = 'var(--plasma-colors-surface-card, rgba(255, 255, 255, 0.12))';
/** Первичный цвет контролов */
export var buttonPrimary = 'var(--plasma-colors-button-primary, #FFFFFF)';
/** Второстепенная кнопка */
export var buttonSecondary = 'var(--plasma-colors-button-secondary, rgba(255, 255, 255, 0.12))';
/** Кнопка для успешного сценария */
export var buttonSuccess = 'var(--plasma-colors-button-success, #21A038)';
/** Цвет предупреждения у контролов */
export var buttonWarning = 'var(--plasma-colors-button-warning, #E35502)';
/** Цвет ошибки у контролов */
export var buttonCritical = 'var(--plasma-colors-button-critical, #FF2E43)';
/** Цвет зажатого контрола */
export var buttonChecked = 'var(--plasma-colors-button-checked, #FFFFFF)';
export var skeletonGradient = 'var(--plasma-colors-skeleton-gradient, linear-gradient( 90deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.08) 6.25%, rgba(255, 255, 255, 0.05) 12.5%, rgba(255, 255, 255, 0.01) 25%, rgba(255, 255, 255, 0.05) 37.5%, rgba(255, 255, 255, 0.08) 43.75%, rgba(255, 255, 255, 0.09) 50%, rgba(255, 255, 255, 0.08) 56.25%, rgba(255, 255, 255, 0.05) 62.5%, rgba(255, 255, 255, 0.01) 75%, rgba(255, 255, 255, 0.05) 87.5%, rgba(255, 255, 255, 0.08) 93.75%, rgba(255, 255, 255, 0.09) 100% ))';
export var skeletonGradientLighter = 'var(--plasma-colors-skeleton-gradient-lighter, linear-gradient( 90deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.32) 6.25%, rgba(255, 255, 255, 0.20) 12.5%, rgba(255, 255, 255, 0.04) 25%, rgba(255, 255, 255, 0.20) 37.5%, rgba(255, 255, 255, 0.32) 43.75%, rgba(255, 255, 255, 0.36) 50%, rgba(255, 255, 255, 0.08) 56.25%, rgba(255, 255, 255, 0.20) 62.5%, rgba(255, 255, 255, 0.04) 75%, rgba(255, 255, 255, 0.20) 87.5%, rgba(255, 255, 255, 0.32) 93.75%, rgba(255, 255, 255, 0.36) 100% ))';
/** Цвет фона баблов отправленный сообщений */
export var speechBubbleSent = 'var(--plasma-colors-speech-bubble-sent, rgba(0, 0, 0, 0.28))';
/** Цвет фона баблов получнных сообщений */
export var speechBubbleReceived = 'var(--plasma-colors-speech-bubble-received, rgba(255, 255, 255, 0.12))';
/** Акцентный цвет бренда */
export var accent = 'var(--plasma-colors-accent, #24B23E)';
/** Акцентный цвет у контролов */
export var buttonAccent = 'var(--plasma-colors-button-accent, #21A038)';
/** Цвет рамки фокуса у контрола */
export var buttonFocused = 'var(--plasma-colors-button-focused, #21A038)';
/** Градиент для заливки основного фона */
export var gradient = 'var(--plasma-colors-gradient, linear-gradient(336.84deg, rgba(20, 116, 70, 0.6) 0%, rgba(8, 8, 8, 0) 64.88%), radial-gradient(100% 100% at 75.89% 100%, rgba(0, 133, 255, 0.24) 0%, rgba(0, 71, 255, 0.03) 100%), linear-gradient(180deg, rgba(8, 8, 8, 0) 50%, rgba(7, 71, 33, 0.3) 100%), linear-gradient(270deg, #061621 0%, rgba(8, 8, 8, 0) 100%))';
/** Градиент для заливки основного фона */
export var gradientDevice = 'var(--plasma-colors-gradient-device, linear-gradient(26.05deg, rgba(15, 153, 24, 0.28) 0%, rgba(8, 8, 8, 0) 72.24%), radial-gradient(100% 100% at 0% 100%, rgba(0, 170, 255, 0.24) 0%, rgba(8, 8, 8, 0) 99.69%), radial-gradient(74.68% 149.35% at 50% 149.35%, rgba(0, 102, 255, 0.6) 0%, rgba(8, 8, 8, 0) 99.69%))';
/** Градиент подсказок о голосовых запросах */
export var voicePhraseGradient = 'var(--plasma-colors-voice-phrase-gradient, linear-gradient(45deg, hsla(130, 75.63025210084032%, 46.666666666666664%, 1) 0%, hsla(189.78947368421052, 78.51239669421487%, 52.54901960784314%, 1) 100%))';
