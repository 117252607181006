import React from "react";

import { Button, TextBox } from "@sberdevices/plasma-ui";
import { Tabs, Row } from "@sberdevices/plasma-ui";
import { IconLogout } from "@sberdevices/plasma-icons";
import { Container } from "@sberdevices/plasma-core";
import { IconCross } from "@sberdevices/plasma-icons";

import { AssistantContext } from "../helpers/AssistantContext";
import { sendText } from "../helpers/Assistant";

import {
  MessageContainer,
  Content,
  TabsItemMobile,
  MobileTabsTextBoxTitleMini,
} from "./styles/tabs-mobile-styles";
import { MessageContainerDraw } from "../style/style";

class StolotoTabsMobile extends React.Component {
  static contextType = AssistantContext;
  constructor(props) {
    super(props);
    this.state = {
      openTabsPopup: false,
    };
  }

  goToPage(assistant, text) {
    this.setState({ openTabsPopup: false });
    sendText(assistant, text);
  }
  getPupup() {
    return (
      <MessageContainerDraw
        style={
          !this.state.openTabsPopup
            ? {
                display: "none",
              }
            : {}
        }
      >
        <MessageContainer
          onClick={() => {
            this.setState({ openTabsPopup: false });
          }}
          style={
            this.state.openTabsPopup
              ? {
                  display: "grid",
                  justifyItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }
              : {
                  display: "none",
                }
          }
        >
          <span
            onClick={() => {
              this.setState({ openTabsPopup: false });
            }}
            style={{
              fontSize: "1.5em",
              cursor: "pointer",
              position: "absolute",
              right: "0.2em",
              top: "0.25em",
            }}
          >
            <IconCross></IconCross>
          </span>
          <Content style={{ width: "312px" }}>
            <Tabs style={{ height: "100%", backgroundColor: "#1A1A1A" }}>
              <div style={{ height: "100%" }}>
                <AssistantContext.Consumer>
                  {(assistant) => (
                    <TabsItemMobile
                      onClick={() => {
                        this.goToPage(assistant, "Основной экран");
                      }}
                    >
                      Лотереи
                    </TabsItemMobile>
                  )}
                </AssistantContext.Consumer>
                <AssistantContext.Consumer>
                  {(assistant) => (
                    <TabsItemMobile
                      onClick={() => {
                        this.goToPage(assistant, "открой мои билеты");
                      }}
                    >
                      Мои билеты
                    </TabsItemMobile>
                  )}
                </AssistantContext.Consumer>
                <AssistantContext.Consumer>
                  {(assistant) => (
                    <TabsItemMobile
                      onClick={() => {
                        this.goToPage(assistant, "Проверить билеты");
                      }}
                    >
                      Проверка билета
                    </TabsItemMobile>
                  )}
                </AssistantContext.Consumer>
                <AssistantContext.Consumer>
                  {(assistant) => (
                    <TabsItemMobile
                      tabItemActive={true}
                      onClick={() => {
                        this.goToPage(assistant, "Выйти из аккаунта");
                      }}
                    >
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "0px",
                        }}
                      >
                        <TextBox style={{ marginRight: "5px" }}>Выход </TextBox>
                        <IconLogout size="s" />{" "}
                      </Row>
                    </TabsItemMobile>
                  )}
                </AssistantContext.Consumer>
              </div>
            </Tabs>
          </Content>
        </MessageContainer>
      </MessageContainerDraw>
    );
  }

  getHeaderPayText() {
    return this.props.showPayText
      ? this.props.payMoney > 0 || "К ОПЛАТЕ: " + this.props.payMoney
      : "";
  }

  render() {
    const bacgroundCol = !this.props.isShowResultPage ? "black" : "";
    const containerStyles = {
      zIndex: 999,
      position: !this.props.waitingPage ? "fixed" : "sticky",
      backgroundColor: bacgroundCol,
    };
    return (
      <>
        {this.getPupup()}

        <Container style={containerStyles}>
          <Row
            style={{
              marginLeft: "2rem",
              marginRight: "2rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "AppName.png"}
              style={{ height: "28px" }}
            />

            <div
              style={{
                visibility: this.props.onlyLogoView && "hidden",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <MobileTabsTextBoxTitleMini>
                {" "}
                {this.getHeaderPayText()}
              </MobileTabsTextBoxTitleMini>
              <Button
                style={{ background: "#080808", padding: "0px" }}
                onClick={() => {
                  this.setState({ openTabsPopup: !this.state.openTabsPopup });
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "menu.png"}
                  style={{ height: "10px", width: "16px", padding: "0px" }}
                />
              </Button>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default StolotoTabsMobile;
