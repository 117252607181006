// Generated by robots, do not change this manually!
export var sberBox = {
    ":root": {
        "font-size": "32px",
        "--plasma-typo-display1-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-display1-fontWeight": "500",
        "--plasma-typo-display1-fontStyle": "normal",
        "--plasma-typo-display1-fontSize": "6rem",
        "--plasma-typo-display1-letterSpacing": "-0.0190em",
        "--plasma-typo-display1-lineHeight": "6rem",
        "--plasma-typo-display2-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-display2-fontWeight": "500",
        "--plasma-typo-display2-fontStyle": "normal",
        "--plasma-typo-display2-fontSize": "3.75rem",
        "--plasma-typo-display2-letterSpacing": "-0.0190em",
        "--plasma-typo-display2-lineHeight": "4rem",
        "--plasma-typo-display3-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-display3-fontWeight": "500",
        "--plasma-typo-display3-fontStyle": "normal",
        "--plasma-typo-display3-fontSize": "3rem",
        "--plasma-typo-display3-letterSpacing": "normal",
        "--plasma-typo-display3-lineHeight": "3.25rem",
        "--plasma-typo-headline1-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-headline1-fontWeight": "700",
        "--plasma-typo-headline1-fontStyle": "normal",
        "--plasma-typo-headline1-fontSize": "2rem",
        "--plasma-typo-headline1-letterSpacing": "-0.0110em",
        "--plasma-typo-headline1-lineHeight": "2.25rem",
        "--plasma-typo-headline2-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-headline2-fontWeight": "600",
        "--plasma-typo-headline2-fontStyle": "normal",
        "--plasma-typo-headline2-fontSize": "1.5rem",
        "--plasma-typo-headline2-letterSpacing": "-0.0220em",
        "--plasma-typo-headline2-lineHeight": "1.75rem",
        "--plasma-typo-headline3-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-headline3-fontWeight": "600",
        "--plasma-typo-headline3-fontStyle": "normal",
        "--plasma-typo-headline3-fontSize": "1.25rem",
        "--plasma-typo-headline3-letterSpacing": "-0.0250em",
        "--plasma-typo-headline3-lineHeight": "1.5rem",
        "--plasma-typo-headline4-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-headline4-fontWeight": "700",
        "--plasma-typo-headline4-fontStyle": "normal",
        "--plasma-typo-headline4-fontSize": "1.25rem",
        "--plasma-typo-headline4-letterSpacing": "-0.0250em",
        "--plasma-typo-headline4-lineHeight": "1.5rem",
        "--plasma-typo-body1-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-body1-fontWeight": "500",
        "--plasma-typo-body1-fontStyle": "normal",
        "--plasma-typo-body1-fontSize": "1rem",
        "--plasma-typo-body1-letterSpacing": "-0.0190em",
        "--plasma-typo-body1-lineHeight": "1.25rem",
        "--plasma-typo-body2-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-body2-fontWeight": "600",
        "--plasma-typo-body2-fontStyle": "normal",
        "--plasma-typo-body2-fontSize": "1rem",
        "--plasma-typo-body2-letterSpacing": "-0.0190em",
        "--plasma-typo-body2-lineHeight": "1.25rem",
        "--plasma-typo-body3-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-body3-fontWeight": "700",
        "--plasma-typo-body3-fontStyle": "normal",
        "--plasma-typo-body3-fontSize": "1rem",
        "--plasma-typo-body3-letterSpacing": "-0.0190em",
        "--plasma-typo-body3-lineHeight": "1.25rem",
        "--plasma-typo-paragraph1-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-paragraph1-fontWeight": "400",
        "--plasma-typo-paragraph1-fontStyle": "normal",
        "--plasma-typo-paragraph1-fontSize": "1rem",
        "--plasma-typo-paragraph1-letterSpacing": "-0.0190em",
        "--plasma-typo-paragraph1-lineHeight": "1.375rem",
        "--plasma-typo-paragraph2-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-paragraph2-fontWeight": "600",
        "--plasma-typo-paragraph2-fontStyle": "normal",
        "--plasma-typo-paragraph2-fontSize": "1rem",
        "--plasma-typo-paragraph2-letterSpacing": "-0.0190em",
        "--plasma-typo-paragraph2-lineHeight": "1.375rem",
        "--plasma-typo-footnote1-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-footnote1-fontWeight": "500",
        "--plasma-typo-footnote1-fontStyle": "normal",
        "--plasma-typo-footnote1-fontSize": "0.875rem",
        "--plasma-typo-footnote1-letterSpacing": "-0.0190em",
        "--plasma-typo-footnote1-lineHeight": "1.125rem",
        "--plasma-typo-footnote2-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-footnote2-fontWeight": "600",
        "--plasma-typo-footnote2-fontStyle": "normal",
        "--plasma-typo-footnote2-fontSize": "0.875rem",
        "--plasma-typo-footnote2-letterSpacing": "-0.0190em",
        "--plasma-typo-footnote2-lineHeight": "1.125rem",
        "--plasma-typo-button1-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-button1-fontWeight": "600",
        "--plasma-typo-button1-fontStyle": "normal",
        "--plasma-typo-button1-fontSize": "1rem",
        "--plasma-typo-button1-letterSpacing": "normal",
        "--plasma-typo-button1-lineHeight": "1.25rem",
        "--plasma-typo-button2-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-button2-fontWeight": "600",
        "--plasma-typo-button2-fontStyle": "normal",
        "--plasma-typo-button2-fontSize": "0.875rem",
        "--plasma-typo-button2-letterSpacing": "normal",
        "--plasma-typo-button2-lineHeight": "1rem",
        "--plasma-typo-caption-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-caption-fontWeight": "500",
        "--plasma-typo-caption-fontStyle": "normal",
        "--plasma-typo-caption-fontSize": "0.75rem",
        "--plasma-typo-caption-letterSpacing": "-0.0220em",
        "--plasma-typo-caption-lineHeight": "1rem",
        "--plasma-typo-underline-fontFamily": "\"SB Sans Text\",\"Helvetica\",\"Arial\",sans-serif",
        "--plasma-typo-underline-fontWeight": "500",
        "--plasma-typo-underline-fontStyle": "normal",
        "--plasma-typo-underline-fontSize": "0.625rem",
        "--plasma-typo-underline-letterSpacing": "0.0125em",
        "--plasma-typo-underline-lineHeight": "0.75rem"
    }
};
