import { IconTicket } from "@sberdevices/plasma-icons";
import { detectDevice } from "@sberdevices/plasma-ui";

import StolotoTabsMobile from "./StolotoTabsMobile";

import { sendText } from "../helpers/Assistant";
import { AssistantContext } from "../helpers/AssistantContext";
import { isFullHD, isTouch } from "../helpers/ScreenUtils";

import {
  StolotoTabsTabItem,
  StolotoTabsImageAppName,
  StolotoTabsMainRow,
  StolotoTabsList,
  StolotoTabsImageLogout,
  StolotoTabsTabItemsTextContentWrapper,
  StolotoFocusButton,
} from "./styles/stoloto-tabs-styles";

type TabId = "lotteries" | "my_tickets" | "check_tickets" | "app_logo" | "exit";

type StolotoTabsProps = {
  waitingPage: boolean;
  messagePopupWasOpened: boolean;
  isShowResultPage: boolean;
  position: string | undefined;
  background: string;
  specialStyles: object;
  imageLogout?: boolean;
  uncheckedTickets?: boolean;
  activeTab: TabId;
  payMoney?: string;
  showPayText?: boolean;
  onlyLogoView?: boolean;
  messageShow?: boolean;
};

type StolotoTabProps = {
  id: TabId;
  messagePopupWasOpened: boolean;
  imageLogout?: boolean;
  tabIndex?: number;
  activeTab: TabId;
  textOnClick: string;
  children: React.ReactNode;
  messageShow?: boolean;
  haveFirstButton?: boolean;
};

const onFocusHandler = (e: React.FocusEvent<HTMLButtonElement>) => {
  const stolotoButton =
    e.target?.parentElement?.querySelector<HTMLButtonElement>(
      ".with-first-button"
    );
  if (!stolotoButton) return;

  stolotoButton.focus();
  stolotoButton.setAttribute("data-focus-visible-added", "");
  stolotoButton.classList.add("focus-visible");
};

export function StolotoTabItem(props: StolotoTabProps) {
  const {
    id,
    activeTab,
    tabIndex = 0,
    textOnClick,
    children,
    haveFirstButton,
    ...other
  } = props;

  return (
    <AssistantContext.Consumer>
      {(assistant) => (
        <>
          {haveFirstButton && (
            <StolotoFocusButton
              role="tab"
              tabIndex={props.messagePopupWasOpened ? -1 : tabIndex}
              onFocus={onFocusHandler}
            />
          )}

          <StolotoTabsTabItem
            className={haveFirstButton ? "with-first-button" : ""}
            isActive={activeTab === id}
            tabIndex={props.messagePopupWasOpened ? -1 : tabIndex}
            imageLogout={props.imageLogout}
            onClick={() => isTouch() && sendText(assistant, textOnClick)}
            onKeyUp={(e) => {
              e.key === "Enter" && sendText(assistant, textOnClick);
            }}
            {...other}
          >
            {children}
          </StolotoTabsTabItem>
        </>
      )}
    </AssistantContext.Consumer>
  );
}

export function StolotoTabs(props: StolotoTabsProps) {
  const position = props?.position ? props.position : "fixed";

  const renderMobileTabs = () => {
    return (
      <StolotoTabsMobile
        onlyLogoView={props.onlyLogoView}
        waitingPage={props.waitingPage}
        isShowResultPage={props.isShowResultPage}
        showPayText={props.showPayText}
        payMoney={props.payMoney}
      />
    );
  };

  const renderDesktopTabs = () => {
    return (
      <StolotoTabsMainRow position={position}>
        <StolotoTabsList style={props.specialStyles}>
          <div>
            <StolotoTabsImageAppName
              src={process.env.PUBLIC_URL + "AppName.png"}
            />
          </div>

          {!props.onlyLogoView && (
            <StolotoTabsTabItemsTextContentWrapper>
              <StolotoTabItem
                id="lotteries"
                haveFirstButton
                messageShow={props.messageShow}
                activeTab={props.activeTab}
                textOnClick="Основной экран"
                messagePopupWasOpened={props.messagePopupWasOpened}
              >
                Лотереи
              </StolotoTabItem>

              <StolotoTabItem
                id="my_tickets"
                messageShow={props.messageShow}
                activeTab={props.activeTab}
                textOnClick="открой мои билеты"
                messagePopupWasOpened={props.messagePopupWasOpened}
              >
                Мои билеты {props.uncheckedTickets && <IconTicket size="xs" />}
              </StolotoTabItem>

              <StolotoTabItem
                id="check_tickets"
                messageShow={props.messageShow}
                activeTab={props.activeTab}
                textOnClick="Проверить билеты"
                messagePopupWasOpened={props.messagePopupWasOpened}
              >
                Проверка билета
              </StolotoTabItem>

              <div
                style={{
                  marginLeft: "2em",
                  marginRight: isFullHD() ? "3em" : undefined,
                }}
              >
                <StolotoTabItem
                  id="exit"
                  messageShow={props.messageShow}
                  activeTab={props.activeTab}
                  textOnClick="Выйти из аккаунта"
                  messagePopupWasOpened={props.messagePopupWasOpened}
                  imageLogout
                >
                  <StolotoTabsImageLogout
                    src={process.env.PUBLIC_URL + "logout.png"}
                  />
                </StolotoTabItem>
              </div>
            </StolotoTabsTabItemsTextContentWrapper>
          )}
        </StolotoTabsList>
      </StolotoTabsMainRow>
    );
  };

  return detectDevice() === "mobile" ? renderMobileTabs() : renderDesktopTabs();
}
