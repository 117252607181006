import React from "react";

import { LineSkeleton } from "@sberdevices/plasma-ui";

import { OnlineTicketsPageTextBoxSubTitleS } from "../components/ticket_check/online_tickets/styles/online-tickets-styles";
import { CardEditionContentTextBoxSubTitleOrange } from "./../components/common/styles/common-elements-styles";

import { AssistantContext } from "./AssistantContext";
import { formatTimeToDraw } from "./TextUtils";

class TimerComponent extends React.Component {
  static contextType = AssistantContext;
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: this.getStartTime(this.props),
      throttle: false,
      timer: null,
      reloadTimeout: null,
      //timeStart: Math.round((drawTime.valueOf() - now.valueOf()) / 1000)
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      timeLeft: this.getStartTime(nextProps),
    });
  }

  getStartTime(props) {
    // will use in constructor instead of set 15
    const drawTime = new Date(props.targetTime);

    const now = new Date();

    let timeStart = Math.round((drawTime.valueOf() - now.valueOf()) / 1000);

    return timeStart;
  }
  reloadTimers() {
    const timer = setInterval(() => {
      const timeLeft = this.getStartTime(this.props) - 1;
      if (timeLeft >= 0) {
        this.setState({
          timeLeft: timeLeft,
        });
      } else {
        this.updateTimerWithNewDraws();
      }
    }, 1000);
    this.setState({ timer });
  }
  updateTimerWithNewDraws() {
    console.log("Updating timer...");
    clearInterval(this.state.timer);
    this.props.timerEndReaction();
    if (!this.props.myTicketsScreen) {
      const reloadTimeout = setTimeout(() => this.reloadTimers(), 1000);
      this.setState({ reloadTimeout });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.reloadTimeout);
    clearInterval(this.state.timer);
  }

  componentDidMount() {
    this.reloadTimers();
  }
  getTimeText() {
    const startTime = this.getStartTime(this.props);
    return (this.props.myTicketsScreen && startTime <= 0) ||
      (this.props.ticketWaiting && startTime <= 0) ? (
      <OnlineTicketsPageTextBoxSubTitleS
        className="memes111"
        ticketWaiting={this.props.ticketWaiting}
        myTicketsScreen={this.props.myTicketsScreen}
      >
        {formatTimeToDraw(-1, this.props.targetTime)}
      </OnlineTicketsPageTextBoxSubTitleS>
    ) : this.state.timeLeft > 0 ? (
      <CardEditionContentTextBoxSubTitleOrange
        myTicketsScreen={this.props.myTicketsScreen}
        mobileDrawPopup={this.props?.mobileDrawPopup}
        gamesCategoryPage={this.props?.gamesCategoryPage}
        drawPopup={this.props.drawPopup}
      >
        {formatTimeToDraw(this.state.timeLeft, this.props.targetTime)}
      </CardEditionContentTextBoxSubTitleOrange>
    ) : (
      <CardEditionContentTextBoxSubTitleOrange
        myTicketsScreen={this.props.myTicketsScreen}
        mobileDrawPopup={this.props?.mobileDrawPopup}
        gamesCategoryPage={this.props?.gamesCategoryPage}
        drawPopup={this.props.drawPopup}
        style={{ width: "75%" }}
      >
        <LineSkeleton size="footnote1" roundness={12} />
      </CardEditionContentTextBoxSubTitleOrange>
    );
  }

  render() {
    return <>{this.getTimeText()}</>;
  }
}

//const TimerComponentMemo = React.memo(TimerComponent);

export default TimerComponent;
