import styled from "styled-components";

import {
  Container,
  Row,
  TextBoxBigTitle,
  TextBoxSubTitle,
  TabItem,
  TextBoxTitle,
} from "@sberdevices/plasma-ui";

import { StyledCarouselItem } from "../../../../style/style";

export const OnlineTicketsPageTextBoxTitle = styled(TextBoxTitle)`
  font-weight: 400;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 32px;
    margin-bottom: 0px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 28px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 14px;
  }
`;
export const OnlineTicketsPageTextBoxSubTitle = styled(TextBoxSubTitle)`
  font-weight: 400;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 28px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 24px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
  }
`;

export const OnlineTicketsPageTextBoxSubTitleS = styled(TextBoxSubTitle)`
  color: #ef6b25;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 24px;
    margin-top: ${(props) => props.myTicketsScreen && "0px"};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 20px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 10px;
    margin-left: ${(props) => props.ticketWaiting && "5px"};
  }
`;

export const OnlineTicketsPageTextBoxTitleL = styled(TextBoxTitle)`
  font-weight: 700;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 40px;
    width: ${(props) => (props.shorterWidth ? "800px" : "1000px")};
    padding-top: 20px;
    padding-left: 7px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 36px;
    width: ${(props) => (props.shorterWidth ? "628px" : "828px")};
    padding-top: 20px;
    padding-left: 0em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 18px;
    width: 380px;
    padding-top: 10px;
  }
`;
export const OnlineTicketsTextBoxBigTitle = styled(TextBoxBigTitle)`
  font-weight: 700;
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-left: 8px;
    font-size: 38px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 32px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 18px;
    margin-top: 0px;
    padding-top: 8px;
  }
`;
export const OnlineTicketsPageStyledCarouselItem = styled(StyledCarouselItem)`
  padding-right: 1em;
  background: #000000;
  //border-bottom: 1px solid rgba(255,255,255,0.2);
  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 15em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    width: 12em;
    //display: flex !important;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    width: 100%;
    padding-top: 0em;
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

export const OnlineTicketPageContainerText = styled(Container)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-left: 0em;
    padding-right: 0em;
    padding-bottom: 3.5em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-left: 0em;
    padding-right: 0px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-left: 2.5em;
    padding-right: 2.5em;
    padding-bottom: 10em;
    height: 100vh;
  }
`;

export const OnlineTicketsPageTabItem = styled(TabItem)`
  user-select: none;
  background-color: #1a1a1a;
  margin-top: 15px;
  width: 350px;
  font-size: 20px;
  height: 32px;
  font-weight: 400;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 24px;
    width: 400px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 10px;
    font-weight: 400;
    width: 155px;
  }
`;

export const CheckTicketTabsContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: row;

  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-left: 2.75em;
  }

  @media (min-width: 961px) and (max-width: 1281px) {
    padding-left: 2em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-left: 2.25em;
    justify-content: center;
    align-items: center;
  }
`;
export const OnlineTicketsTabsRow = styled(Row)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    //padding-top: 12%;
  }

  @media (min-width: 961px) and (max-width: 1281px) {
    // padding-top: 8%;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-top: 2.5em;
  }
`;

export const OnlineTicketsPageTabItemFinGame = styled(OnlineTicketsPageTabItem)`
  background-color: #000000;
  user-select: none;
  font-weight: 400;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 24px;
    width: 400px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    width: 170px;
    font-size: 10px;
    font-weight: 400;
  }
`;
