import React from "react";

import { AssistantContext } from "../../helpers/AssistantContext";

export interface PageProps {
  command: string;
  render: (payload: Record<string, any>) => React.ReactNode;
  payload: Record<string, any>;
}

export class Page extends React.Component<PageProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }
  static contextType = AssistantContext;
  context!: React.ContextType<typeof AssistantContext>;

  render() {
    return <>{this.props.render(this.props.payload)}</>;
  }
}
