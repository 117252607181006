import styled from "styled-components";

import {
  Row,
  Col,
  Card,
  TextBox,
  TextBoxBigTitle,
  TextBoxSubTitle,
  Button,
} from "@sberdevices/plasma-ui";

import { StyledCarouselItem, StyledCarousel } from "../../../../style/style";

export const ButtonSpec = styled(Button)`
  color: ${(props) => props.colorBut};
`;
export const LottoTicketsControllerTextBoxBigTitle = styled(TextBoxBigTitle)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-left: ${(props) => (props.isTop3Game ? "0px" : "1.4em")};
    font-size: 36px;
    letter-spacing: 1px;
    height: ${(props) => props.isTop3Game && "26px"};
    padding-top: ${(props) => props.isTop3Game && "4px"};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    //margin-left: 1.7em;
    font-size: 28px;
    letter-spacing: 1px;
    padding-left: ${(props) => props.isTop3Game && "5px"};
    height: ${(props) => (props.isTop3Game ? "23px" : "23px")};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-left: 17px;
    font-size: 18px;
    padding-top: ${(props) => props.isKeno && "10px !important"};
    margin-top: ${(props) => props.isTop3Game && "7px !important"};
    height: ${(props) => props.isTop3Game && "16px"};
  }
`;

export const LottoKenoThirdGameRow = styled(Row)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    //border-top: 1px solid rgba(255, 255, 255, 0.2);
    height: 24em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 17em;
    //padding-left: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

export const LottoTicketControllerRowHeader = styled(Row)`
  padding-top: 10px;
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-left: ${(props) =>
      props.specPaddingLeft ? props.specPaddingLeft : "0px"};
    padding-top: ${(props) => props.specPaddingTop};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-top: 13px;
    padding-left: ${(props) =>
      props.specPaddingLeft ? props.specPaddingLeft : "0px"};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-top: ${(props) => props.isKenoGame && "10px"};
  }
`;

export const LottoTicketsControllerTextBoxBigTitleFirstField = styled(
  LottoTicketsControllerTextBoxBigTitle
)`
  margin: 0px;
  space-between: 1px;
  letter-spacing: 0px;
  @media (min-width: 140px) and (max-width: 960px) {
    height: 100%;
    margin-left: ${(props) => props.specialMargins};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    // height: 21px;
    padding-left: 0px;
    //letter-spacing: 1px;
    height: ${(props) =>
      !props.thisIsTop3TicketNumber
        ? props.haveNoTicketRules
          ? "35px"
          : "60px"
        : "21px"};
  }
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-top: ${(props) =>
      props.headerPadding ? props.headerPadding : "7px"};
    //padding-left: 6px;
  }
`;
export const LottoTicketsControllerStyledCarousel = styled(StyledCarousel)`
  user-select: none;
  @media (min-width: 140px) and (max-width: 960px) {
    height: 100%;
    display: ${(props) =>
      props.isMustBeDisplayContents ? "contents" : "flex"};
    flex-direction: row;
    align-items: center;
    &:first-child {
      flex-direction: row;
    }
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-top: 0px;
  }
  @media (min-width: 1281px) and (max-width: 1961px) {
    // padding-left: 3px;
    // height: 22em;
  }
`;

export const LottoTicketControllerMainRow = styled(Row)`
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  user-select: none;
`;

export const LottoTicketsControllerStyledCarouselItem = styled(
  StyledCarouselItem
)`
  //width: 100%;
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-top: 0px;
    border-bottom: ${(props) =>
      props.isKeno && "1px solid rgba(255,255,255,0.2)"};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-top: 0px;
    padding-bottom: ${(props) => props.isKeno && "11px"};
    border-bottom: ${(props) =>
      props.isKeno && "1px solid rgba(255,255,255,0.2)"};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-top: 0px;
  }
`;

export const LottoTicketsControllerTextBoxSubTitle = styled(TextBoxSubTitle)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    //margin-top: -0.4em;
    font-size: 24px;
    margin-top: 8px;
    margin-bottom: 1.5em !important;
    padding-left: ${(props) =>
      props.specPadding ? props.specPadding : "15px"};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    //margin-top: -0.8em;
    font-size: 20px;
    padding-left: ${(props) =>
      props.specPadding ? props.specPadding : "24px"};
    // margin-bottom: 1.5em !important;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-top: ${(props) =>
      props.specMarginTopMobile ? props.specMarginTopMobile : "-1.2em"};
    font-size: 12px;
    margin-bottom: 1em !important;
    //margin-left: 9px;
  }
`;

export const LottoTicketsControllerTextBoxBigTitleSquare = styled(
  TextBoxBigTitle
)`
  font-weight: 400;
  color: white;
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-top: -3px;
    font-size: 24px;
    margin-bottom: 0px;
    padding-left: ${(props) =>
      props.specPadding ? props.specPadding : "10px"};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin: 0px;
    font-size: 20px;
    height: 25px;
    padding-left: ${(props) =>
      props.specPadding ? props.specPadding : "10px"};
    margin-bottom: 10px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    // margin-top: 0.5em;
    margin: 0px;
    font-size: 10px;
    margin-bottom: 0px;
    //margin-left: 5px;
  }
`;

export const LottoTicketsControllerTextBoxSubTitleRule = styled(
  LottoTicketsControllerTextBoxSubTitle
)`
  margin-bottom: 0px !important;

  @media (min-width: 1281px) and (max-width: 1961px) {
    //padding-left: 7px;
    padding-left: ${(props) => !props.isRowDimention && "7px"};
    margin-left: ${(props) => props.specialMargin};
    margin-top: ${(props) =>
      props.isRowDimention
        ? props.specialMarginsTop
          ? props.specialMarginsTop
          : "2px"
        : "3px"};
    // margin-top:1px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-top: 0px;
    padding-left: 0px;
    padding-top: ${(props) =>
      props.specialPaddingTop ? props.specialPaddingTop : "6px"};
    margin-left: ${(props) => props.specialMargin};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-top: ${(props) => (props.specialMargins ? "-25px" : "-3px")};
    margin-left: ${(props) => (props.specialMargins ? "5px" : "0px")};
    padding-left: ${(props) => props.addPaddingLeft && "3px"};
    padding-bottom: ${(props) => props.secondSpecialPadding && "4px"};
  }
`;
export const LottoTicketsControllerTextBoxSubTitleRuleRow = styled(
  TextBoxSubTitle
)`
  margin-bottom: 0px !important;

  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-left: 20px;
    // margin-left: ${(props) => props.specialMargin};
    font-size: 24px;
    // margin-top:1px;
  }
`;

export const LottoTicketsControllerTextBoxSubTitleRuleKeno = styled(
  LottoTicketsControllerTextBoxSubTitle
)`
  margin-bottom: 0px !important;
  margin-top: 0px;
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-left: 3px;
    margin-top: -20px !important;
    // margin-top:1px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-left: 10px;
    font-size: 18px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    margin-left: ${(props) => (props.specialMargins ? "5px" : "0px")};
    padding-left: 4px;
  }
`;

export const LottoTicketsControllerCard = styled(Card)`
  user-select: none;
  &:focus {
    outline: none;
    border: white;
    border-color: white;
  }
  @media (min-width: 1281px) and (max-width: 1961px) {
    //margin-right: 1.5em;
    //max-width: 40.5em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    //margin-top: 1em;
    // max-width: 27.5em;
    //margin-right: 1em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    //margin-top: 0.5em;
    padding-left: ${(props) => !props.isCentered && props.paddingLeft};
    // padding-right: ${(props) => (props.isHorisontal ? "0em" : "2em")};
    // margin-left: ${(props) => (props.ticketsMoreThanOne ? "0em" : "0em")};
    width: 312px;
    display: flex;

    align-items: ${(props) => props.isCentered && "center"};
  }
`;

export const SecondTicketRow = styled(Row)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    //margin-right: 1.5em;
    //max-width: 40.5em;
    padding-top: 8px;
    //margin-left: -7px;
    padding-bottom: ${(props) => props.paddingBottom};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-right: 0px;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    padding-bottom: ${(props) => props.paddingBottom};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-top: 0px;
    //padding-left: 4px;
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "20px"};
  }
`;

export const LottoTicketsControllerRow = styled(Col)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-left: ${(props) => (props.isTop3Game ? "0em" : "0.9em")};
    padding-right: ${(props) => (props.isTop3Game ? "0em" : "0.9em")};
    height: ${(props) =>
      props.isTop3Game ? "23em" : (props) => props.settingHeight};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-left: ${(props) => (props.isTop3Game ? "0em" : "0.5em")};
    padding-right: ${(props) => (props.isTop3Game ? "0em" : "0.5em")};
    height: ${(props) => props.settingHeight};
    //height: 14.5em;
    // height: 23em !important;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-right: 0.2em;
    padding-left: 0.2em;
    padding-bottom: 0.2em;
    height: ${(props) => props.settingHeight};
  }
`;

export const LottoTicketsControllerTextBoxFirstTicket = styled(TextBox)`
  //padding-top: 0.5em;
  @media (min-width: 1281px) and (max-width: 1961px) {
    height: 45px;
    padding-left: 10px;
  }
`;

export const LottoTicketsControllerCol = styled(Col)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    // margin-top: 3.4em;

    padding-left: ${(props) =>
      props.getPaddingLeft
        ? props.getPaddingLeft
        : props.getPaddingLeft
        ? "0em"
        : "2.1em"};
    padding-top: ${(props) =>
      props.specPaddingTop
        ? props.specPaddingTop
        : props.getPaddingLeft
        ? "0em"
        : "0.8em"};
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    // margin-top: 2.1em;
    // padding-left: 1em;
    padding-top: ${(props) => props.specPaddingTop};
    padding-left: ${(props) => props.getPaddingLeft};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    //margin-top: 9px;
    padding-top: ${(props) => props.specPaddingTop};
    padding-left: ${(props) =>
      props.getPaddingLeft ? props.getPaddingLeft : "5px"};
    padding-bottom: 7px;
  }
`;

export const LottoTicketsControllerTextBoxSecondTicket = styled(TextBox)`
  @media (min-width: 140px) and (max-width: 960px) {
    //margin-top: -15px;
    //margin-bottom: 7px;
    padding-left: 0px;
  }
`;

export const LottoTicketsColTop3 = styled(Col)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    // margin-top: 3.4em;
    padding-left: 0px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    // margin-top: 2.1em;
    // padding-left: 1em;
    padding-left: 11px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-left: 0px;
  }
`;

export const LottoTicketsCol = styled(Col)`
@media (min-width: 1281px) and (max-width: 1961px) {
    // margin-top: 3.4em;
    
    padding-left: ${(props) => props.isTop3Game && "34px"}; 
}
@media (min-width: 961px) and (max-width: 1281px) {
    // margin-top: 2.1em;
    // padding-left: 1em;
    padding-top: ${(props) => props.isTop3Game && "7px"};
 }  
@media (min-width: 140px) and (max-width: 960px) {   
    margin-top: 1px;
    padding-left: ${(props) => props.paddingLeftOnMobile && "0px"}
    padding-top: ${(props) => props.isTop3Game && "53px"};
    margin-left: ${(props) => props.isTop3Game && "18px"};
} 
`;
