import styled from "styled-components";

import {
  Container,
  Col,
  TextBoxBigTitle,
  TextBoxSubTitle,
  Button,
  TextBoxBiggerTitle,
} from "@sberdevices/plasma-ui";

export const ShowTicketCheckResultWinLooseTitle = styled(TextBoxBiggerTitle)`
  color: ${(props) => (props.win ? "#54B64E" : "#EE6820")};

  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: ${(props) => (props.win ? "120px" : "60px")};
    margin-top: 29px;
    //margin: ${(props) => props.win || "10px"};
    margin-left: ${(props) => (props.win ? "-7px" : "-7px")};
    margin-bottom: 42px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: ${(props) => (props.win ? "70px" : "45px")};
    // margin-top: ${(props) => props.win && "0px"};
    // margin: ${(props) => props.win && "0px"};
    margin-bottom: 3px;
    margin-left: -3px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: ${(props) => (props.win ? "48px" : "30px")};
    margin-top: ${(props) => props.win || "2px"};
    //margin: ${(props) => props.win || "2px"};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "1px")};
    margin-bottom: 5px;
  }
`;

export const ShowTicketCheckResulCol = styled(Col)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    max-width: 15em;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    max-width: 15em;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    max-width: 19em;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;
export const ShowTicketCheckResulMainContainer = styled.div`
  /* background-image: url(${(props) =>
    props.win && "/main_images/background_win.png"}); */
`;
export const ShowTicketCheckResulContainer = styled(Container)`
  //background-image: url("/main_images/background_win.png");
  @media (min-width: 1281px) and (max-width: 1961px) {
    margin-top: 2em;
    height: 100%;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    margin-top: 2em;
    height: 100%;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    padding-top: 4.75em;
    //height: 100%;
    //padding-top: 3.75em;
  }
`;

export const ShowTicketCheckResultTextBoxBigTitle = styled(TextBoxBigTitle)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 48px;
    margin-left: -5px;
    margin-bottom: 0px;
    padding-bottom: 20px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 40px;
    margin-bottom: 0px;
    padding-bottom: 10px;
    margin-left: ${(props) => props.marginLeft};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 20px;
    margin-left: -3px;
    margin-bottom: 10px;
  }
`;

export const ShowTicketCheckResulSmallHeaderText = styled(TextBoxSubTitle)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 28px;
    //margin: 10px;
    margin-left: -4px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 24px;
    margin-left: -2px;
    margin-bottom: -3px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 12px;
    //margin: 2px;
    margin-top: 0px;
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "1px")};
    margin-bottom: 0px;
  }
`;

export const ShowTicketCheckResulHeaderText = styled(TextBoxBigTitle)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 48px;
    //margin: 10px;
    //padding-left: 0px;
    padding-bottom: 20px;
    margin-left: -5px;
    margin-bottom: 0px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 40px;
    margin: 0px;
    margin-left: -3px;
    padding-bottom: 10px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 20px;
    //padding-top: 0px;
    margin-bottom: 10px;
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  }
`;

export const ShowTicketCheckResultUnplayedNumbersTitle = styled(
  TextBoxBigTitle
)`
  color: #ee6820;
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 30px;
    // margin: 10px;
    margin-left: -5px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 28px;
    margin: 0px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -2px;
    //margin: 2px;
  }
`;

export const ShowTicketCheckResultMyTicketButton = styled(Button)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    height: 80px;
    width: 480px;
    font-size: 26px;
    margin-top: ${(props) => (props.firstButton ? "45px" : "25px")};
    //margin-top: 20px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 72px;
    width: 380px;
    font-size: 20px;
    margin-top: ${(props) => (props.firstButton ? "1.5em" : "1em")};
  }
  @media (min-width: 140px) and (max-width: 960px) {
    height: 40px;
    width: 312px;
    font-size: 14px;
    margin-left: -2px;
    margin-top: ${(props) => (props.firstButton ? "15px" : "10px")};
  }
`;

export const ShowTicketCheckResultNumberTicketTitle = styled(TextBoxBigTitle)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 36px;
    //margin: 10px;
    //margin-left: ${(props) => props.marginLeft};
    margin-top: 0px;
    margin-bottom: 5px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 25px;
    //margin-left: -4px;
    margin: 0px;
    margin-left: -2px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 18px;
    margin: 2px;
    margin-left: ${(props) =>
      props.marginLeftMobile ? props.marginLeftMobile : "0px"};
  }
`;
