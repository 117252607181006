import React from "react";

import { KenoFirstGameTabItem } from "./styles/keno-styles";

class KenoFirstGameTabItemComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  setFirstGameChoiseKeno = () => {
    this.props.setFirstGameChoiseKeno(
      this.props.gameKey,
      this.props.ticketNumber,
      this.props.keyIndex
    );
  };
  render() {
    console.log(this.props.waitResult);
    return (
      <>
        <KenoFirstGameTabItem
          waitResult={this.props.waitResult}
          tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
          onClick={this.setFirstGameChoiseKeno}
          //isActive={gameItem.key === this.props.ticketInfo.firstGame}
          activeTab={this.props.activeTab}
          id={this.props.keyIndex}
          key={this.props.key}
        >
          {this.props.rusName}
        </KenoFirstGameTabItem>
      </>
    );
  }
}

const KenoFirstGameTabItemComponentMemo = React.memo(
  KenoFirstGameTabItemComponent
);

export default KenoFirstGameTabItemComponentMemo;
