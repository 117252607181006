import React from "react";

import ControlTicketForm from "./ControlTicketForm";

import { addGeneralCommand } from "../../helpers/Assistant";
import { AssistantContext } from "../../helpers/AssistantContext";
import { detectDeviceSize } from "../../helpers/ScreenUtils";
import { sendAction } from "../../helpers/Assistant";
import { setPageCommands } from "../../helpers/Assistant";

import { SCREEN_KENO } from "../constants";
import { getRandomSetOfNumbers, getScreenMode } from "./utils";
class ScreenKeno extends React.Component {
  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    this.state = {
      tickets: this.props?.selectedTicketNumbers || [
        {
          key: 0,
          secondGame: new Set(),
          threeGame: new Set(),
          firstGame: null,
        },
      ],
      selectedTicketKey: 0,
      withSelectorControl: false,
      screenMode: getScreenMode(),
      sumToPay: 0,
      ticketsQty: 0,
      ticketCarouselIndex: 0,
    };
  }

  componentDidMount() {
    addGeneralCommand(this.context, "DRAW_CHANGE", (action) => {
      this.setState(action.payload);
    });
  }

  componentWillUnmount() {
    setPageCommands(this.context, {});
  }
  addNewTicket = () => {
    let tickets = this.state.tickets;
    tickets.push({
      key: tickets.length,
      secondGame: new Set(),
      threeGame: new Set(),
      firstGame: null,
    });
    this.setState({ tickets: tickets, withSelectorControl: true });
  };

  selectTicket = (value) => {
    this.setState({ selectedTicketKey: value + 1 });
  };

  removeTicket = (ticketId) => {
    let currentTickets = this.state.tickets;
    if (currentTickets.length > 1) {
      currentTickets.splice(ticketId, 1);
      this.setState({ tickets: currentTickets });
    }
  };

  clearTicket = (ticketId) => {
    let currentTickets = this.state.tickets;
    currentTickets[ticketId].secondGame = new Set();
    currentTickets[ticketId].threeGame = new Set();
    currentTickets[ticketId].firstGame = null;
    this.setState({ tickets: currentTickets });
    this.getPaySum(currentTickets);
  };

  setRandomNumbers = (ticketKey) => {
    let tickets = this.state.tickets;
    const screenMax = SCREEN_KENO.secondGame.firstTicket.restrictions.max;
    const maxSize = screenMax > 1 ? screenMax - 1 : screenMax;
    tickets[ticketKey].secondGame = getRandomSetOfNumbers(
      maxSize,
      SCREEN_KENO["secondGame"]["firstTicket"][detectDeviceSize()][
        "maxSquareViewed"
      ] + 1
    );
    this.getPaySum(tickets);
    this.setState({ tickets: tickets });
  };

  setTicketNumber = (currentNumber, ticketNumber, ticketStatus) => {
    const tickets = this.state.tickets;

    for (let ticket in tickets) {
      let currentTicket = tickets[ticket];
      if (currentTicket.key == ticketNumber) {
        let targetGame =
          ticketStatus == "mainTicket" ? "secondGame" : "threeGame";
        if (currentTicket[targetGame].delete(currentNumber)) {
          break;
        }
        if (
          currentTicket[targetGame].size <
          SCREEN_KENO[targetGame].firstTicket.restrictions.max
        ) {
          currentTicket[targetGame].add(currentNumber);
          break;
        }
      }
    }

    this.getPaySum(tickets);
  };

  getPaySum(tickets) {
    let ticketsQty = 0;
    let sumToPay = 0;
    for (let ticket in tickets) {
      let sumWasIncrease = false;
      if (tickets[ticket].firstGame != null) {
        sumToPay = sumToPay + this.props.gameInfo.draw.betCost;
        sumWasIncrease = true;
      }
      if (tickets[ticket].secondGame.size > 0) {
        sumToPay = sumToPay + this.props.gameInfo.draw.betCost;
        sumWasIncrease = true;
      }
      if (tickets[ticket].threeGame.size > 0) {
        sumToPay = sumToPay + this.props.gameInfo.draw.betCost * tickets[ticket].threeGame.size;
        sumWasIncrease = true;
      }

      if (sumWasIncrease) {
        ticketsQty += 1;
      }
    }

    this.setState({
      sumToPay: sumToPay,
      ticketsQty: ticketsQty,
      tickets: tickets,
    });
    //return {'sumToPay': sumToPay, 'ticketQty':ticketQty}
  }

  setTicketCarouselIndex = (ticketCarouselIndex) => {
    this.setState({ ticketCarouselIndex: ticketCarouselIndex });
  };

  setFirstGameChoise = (chosenKey, ticketKey, ticketCarouselIndex) => {
    const tickets = this.state.tickets;
    const ticketIndex = tickets.findIndex((ticket) => ticket.key == ticketKey);
    if (tickets[ticketIndex].firstGame != chosenKey) {
      tickets[ticketIndex].firstGame = chosenKey;
    } else {
      tickets[ticketIndex].firstGame = null;
    }
    this.getPaySum(tickets);
    this.setState({
      tickets: tickets,
      ticketCarouselIndex: ticketCarouselIndex,
    });
  };
  sendSevenColsFilled() {
    sendAction(this.context, { action_id: "FILLED_7", parameters: {} });
  }
  setThreeGameChoise = (chosenKey, ticketKey) => {
    const tickets = this.state.tickets;
    const ticketIndex = tickets.findIndex((ticket) => ticket.key == chosenKey);

    if (
      !tickets[ticketIndex].threeGame.has(ticketKey) ||
      tickets[ticketIndex].threeGame === null
    ) {
      tickets[ticketIndex].threeGame.add(ticketKey);
      if (
        tickets[ticketIndex].threeGame.size == 7 &&
        !tickets[ticketIndex]?.sevenColsWasSend
      ) {
        this.sendSevenColsFilled();
        tickets[ticketIndex]["sevenColsWasSend"] = true;
      }
    } else {
      tickets[ticketIndex].threeGame.delete(ticketKey);
    }
    this.getPaySum(tickets);
    this.setState({ tickets: tickets });
  };

  setThreeGameEmpty = (ticketKey) => {
    const tickets = this.state.tickets;

    tickets[ticketKey].threeGame.clear();
    this.getPaySum();
    this.setState({ tickets: tickets });
  };

  render() {
    return (
      <>
        <ControlTicketForm
          messagePopupWasOpened={this.props.messagePopupWasOpened}
          messagePopupWasOpenedFunction={
            this.props.messagePopupWasOpenedFunction
          }
          messageShow={this.props.messageShow}
          withSelectorControl={true}
          ticketQty={this.state.ticketsQty}
          paySum={this.state.sumToPay}
          gameInfo={this.props.gameInfo}
          addNewTicket={this.addNewTicket}
          tickets={this.state.tickets}
          selectedTicketKey={this.state.selectedTicketKey}
          setTicketNumber={this.setTicketNumber}
          gameName={this.props.gameInfo.name}
          rules={SCREEN_KENO}
          rowsFirstPlayingField={
            SCREEN_KENO["secondGame"]["firstTicket"][detectDeviceSize()]["rows"]
          }
          colsFirstPlayingField={
            SCREEN_KENO["secondGame"]["firstTicket"][detectDeviceSize()]["cols"]
          }
          maxSquareViewedFirstPlayingField={
            SCREEN_KENO["secondGame"]["firstTicket"][[detectDeviceSize()]][
              "maxSquareViewed"
            ]
          }
          rowsSecondPlayingField={
            SCREEN_KENO["threeGame"]["firstTicket"]["rows"]
          }
          colsSecondPlayingField={
            SCREEN_KENO["threeGame"]["firstTicket"]["cols"]
          }
          maxSquareViewedSecondPlayingField={
            SCREEN_KENO["threeGame"]["firstTicket"]["maxSquareViewed"]
          }
          setRandomNumbers={this.setRandomNumbers}
          selectTicket={this.selectTicket}
          setFirstGameChoise={this.setFirstGameChoise}
          ticketWaiting={this.props?.ticketWaiting}
          setThreeGameChoise={this.setThreeGameChoise}
          removeTicket={this.removeTicket}
          clearTicket={this.clearTicket}
          ticketCarouselIndex={this.state.ticketCarouselIndex}
          setTicketCarouselIndex={this.setTicketCarouselIndex}
          waitResult={this.props?.ticketWaiting}
          setThreeGameEmpty={this.setThreeGameEmpty}
        />
      </>
    );
  }
}

export default ScreenKeno;
