import React from "react";

import { PageProps } from "./Page";

import { addGeneralCommand } from "../../helpers/Assistant";
import { AssistantContext } from "../../helpers/AssistantContext";

type Page = React.ReactElement<PageProps>;
type PageManagerProps = { default?: string; children: Array<Page> };
type PageManagerState = {
  currentPage?: string;
  pages: Record<string, Page>;
  currentPayload?: Record<string, any>;
  pageProps: Record<string, any>;
};

export class PageManager extends React.Component<
  PageManagerProps,
  PageManagerState
> {
  static contextType = AssistantContext;
  context!: React.ContextType<typeof AssistantContext>;

  constructor(props: PageManagerProps) {
    super(props);
    this.state = { currentPage: props.default, pages: {}, pageProps: {} };
  }

  componentDidMount() {
    this.props.children.forEach((page) => {
      const command = page.props.command;
      addGeneralCommand(this.context, command, (payload) => {
        this.setState({ currentPage: command, currentPayload: payload });
        window.history.pushState(null, "", window.location.href); // Push state for back button to work
      });
      const stateUpdate: PageManagerState = {
        ...this.state,
        pages: this.state.pages,
      };
      stateUpdate.pages[command] = page;
      this.setState(stateUpdate);
    });
  }

  render() {
    return (
      <>
        {this.state.currentPage
          ? React.cloneElement(this.state.pages[this.state.currentPage], {
              payload: this.state.currentPayload,
            })
          : null}
      </>
    );
  }
}
