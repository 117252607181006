import React from "react";
import AuthPage from "./components/AuthPage";
import MainMenu from "./components/MainMenuPage";
import Message from "./components/Message";
import ScreenLotto from "./components/lotto/ScreenLotto";
import ScreenBingo from "./components/bingo/ScreenBingo";
import ScreenTop3 from "./components/lotto/ScreenTop3";
import ScreenKeno from "./components/lotto/ScreenKeno";
import { PageManager } from "./components/navigation/PageManager";
import { Page } from "./components/navigation/Page";
import { addListeners } from "./components/navigation/KeyboardManager";
import MyTickets from "./components/ticket_check/offline_ticket/CheckOflineTicketForm";
import MyGames from "./components/ticket_check/offline_ticket/MyGames";
import WaitingResultsPage from "./components/ticket_check/WaitingResultsPage";
import ShowTicketCheckResult from "./components/ticket_check/ShowTicketCheckResult";
import OnlineTicketsPage from "./components/ticket_check/online_tickets/OnlineTicketsPage";
import { AssistantContext } from "./helpers/AssistantContext";
import Loader from "./components/Loader";

class App extends React.Component {
  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    this.state = {
      messageShow: false,
      messagePopupWasOpened: false,
      gameName: null,
    };
  }

  toggleMessageShow = (arg) => {
    this.setState({ messageShow: arg });
  };

  messagePopupWasOpened = (arg) => {
    if (!arg) {
      if (window.lastFocusedElement) {
        this.setState({ messagePopupWasOpened: false }, () =>
          window.lastFocusedElement.focus()
        );
      }
    } else {
      this.setState({ messagePopupWasOpened: true });
    }
  };

  componentDidMount = () => {
    addListeners(this.context);
  };

  render() {
    return (
      <>
        <PageManager>
          <Page
            command="SCREEN_AUTH"
            render={() => (
              <AuthPage
                messagePopupWasOpened={this.state.messagePopupWasOpened}
              />
            )}
          />
          <Page
            command="SCREEN_MAIN"
            render={(action) => (
              <MainMenu
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                games={action.payload.games}
                uncheckedTickets={action.payload.unchecked_tickets}
              />
            )}
          />
          <Page
            command="SCREEN_BINGO"
            render={(action) => (
              <ScreenBingo
                messagePopupWasOpenedFunction={this.messagePopupWasOpened}
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                gameInfo={action.payload.lottery.GameInfo}
                ticketsInfo={action.payload.lottery.tickets}
                drawId={action.payload.lottery.draw_id}
              />
            )}
          />
          <Page
            command="SCREEN_LOTTO"
            render={(action) => (
              <ScreenLotto
                messagePopupWasOpenedFunction={this.messagePopupWasOpened}
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                gameInfo={action.payload.lottery.GameInfo}
                defaultTickets={[
                  {
                    key: 0,
                    selectedNumbersFirstTicket: new Set(),
                    selectedNumbersSecondTicket: new Set(),
                  },
                ]}
              />
            )}
          />
          <Page
            command="SCREEN_TOP3"
            render={(action) => (
              <ScreenTop3
                messagePopupWasOpenedFunction={this.messagePopupWasOpened}
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                gameInfo={action.payload.lottery.GameInfo}
                ticketsInfo={action.payload.lottery.tickets}
              />
            )}
          />
          <Page
            command="SCREEN_KENO"
            render={(action) => (
              <ScreenKeno
                messagePopupWasOpenedFunction={this.messagePopupWasOpened}
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                gameInfo={action.payload.lottery.GameInfo}
              />
            )}
          />
          <Page
            command="SCREEN_MY_TICKETS"
            render={(action) => (
              <OnlineTicketsPage
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                ticketsInfo={action.payload.TicketInfo}
              />
            )}
          />
          <Page
            command="SCREEN_CHECK_OFFLINE"
            render={() => (
              <MyGames
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
              />
            )}
          />
          <Page
            command="SHOW_TICKET_ONLINE"
            render={(action) => (
              <ShowTicketCheckResult
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                onlineTicket={true}
                ticketsInfo={action.payload ? action.payload : null}
              />
            )}
          />
          <Page
            command="SCREEN_TICKET_INPUT"
            render={(action) => (
              <MyTickets
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                gameInfo={action.payload}
              />
            )}
          />
          <Page
            command="WAITING_RESULTS_PAGE"
            render={(action) => (
              <WaitingResultsPage
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                messageShow={this.state.messageShow}
                ticketsInfo={action.payload ? action.payload : null}
              />
            )}
          />
          <Page
            command="SHOW_TICKET_OFFLINE"
            render={(action) => (
              <ShowTicketCheckResult
                messagePopupWasOpened={this.state.messagePopupWasOpened}
                offline={true}
                messageShow={this.state.messageShow}
                onlineTicket={false}
                ticketsInfo={action.payload ? action.payload : null}
              />
            )}
          />
        </PageManager>
        <Message
          messagePopupWasOpened={this.messagePopupWasOpened}
          messageShow={this.state.messageShow}
          toggleMessageShow={this.toggleMessageShow}
        />
        <Loader />
      </>
    );
  }
}
export default App;
