import {
  createAssistant,
  createSmartappDebugger,
  AssistantVisibilityCommand,
} from "@salutejs/client";

export interface Action {
  type: string;
  payload?: Record<string, unknown>;
}
export interface CommandSet {
  [command: string]: (arg: Action) => void;
}
export interface Assistant {
  assistant: ReturnType<typeof createAssistant>;
  page_commands: CommandSet;
  general_commands: CommandSet;
  spinnerShow: boolean;
  setSpinnerShow: (val: boolean) => void;
}

export function getAssistant(): Assistant {
  const token: string | null = localStorage.getItem("sber_token");
  let rawAssistant: ReturnType<typeof createAssistant>;
  if (token) {
    rawAssistant = createSmartappDebugger({
      token: token,
      initPhrase: "запусти столото",
      getState: () => {
        return {};
      },
    });
  } else {
    rawAssistant = createAssistant({
      getState: () => {
        return {};
      },
    });
  }
  const ret = {
    assistant: rawAssistant,
    page_commands: {},
    general_commands: {},
    spinnerShow: false,
    setSpinnerShow: () => {},
  };
  rawAssistant.on("data", _ondata.bind(null, ret));
  rawAssistant.subscribeToCommand("visibility", (command) => {
    const visibilityCommand = command as AssistantVisibilityCommand;
    if (visibilityCommand.visibility === "visible") {
      sendText(ret, "/start");
    }
  });
  return ret;
}

export function setPageCommands(assistant: Assistant, commands: CommandSet) {
  assistant.page_commands = commands;
}

export function addGeneralCommand(
  assistant: Assistant,
  command_id: string,
  callback: (arg: Action) => void
) {
  assistant.general_commands[command_id] = callback;
}

function _ondata(assistant: Assistant, command: any): void {
  console.log("received");
  console.log(command);
  if (command.type !== "smart_app_data") {
    return;
  }
  const commandType = command.smart_app_data.type;
  if (commandType in assistant.page_commands) {
    assistant.page_commands[commandType](command.smart_app_data);
  } else if (commandType in assistant.general_commands) {
    assistant.general_commands[commandType](command.smart_app_data);
  } else {
    console.error(`Unknown command ${commandType}`);
  }
  if (!["INVOICE_REGISTER_STATUS"].includes(command.smart_app_data.type)) {
    assistant.spinnerShow = false;
  }
}

export function sendAction(assistant: Assistant, action: any) {
  console.log("Sending Action");
  console.log(action);
  if (
    ![
      "GET_DRAWS",
      "GET_TOKEN",
      "CLOSE_MESSAGE",
      "CHANGE_DRAW",
      "MINIMAL_BET",
      "MULTIPLE_BET",
      "FILLED_7",
      "CHECK_PAYMENT",
    ].includes(action.action_id)
  ) {
    assistant.spinnerShow = true;
  }
  assistant.assistant.sendData({ action });
}

export function sendText(assistant: Assistant, text: string) {
  console.log("Sending text");
  console.log(text);
  if (!["Понятно", "CLOSE_MESSAGE"].includes(text)) {
    assistant.spinnerShow = true;
  }

  assistant.assistant.sendData({
    action: { action_id: "TEXT", parameters: { text } },
  });
}
