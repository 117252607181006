import React from "react";

import { Container, Card, Row, detectDevice } from "@sberdevices/plasma-ui";
import { Col } from "@sberdevices/plasma-core";

import { StolotoTabs } from "../../StolotoTabs";
import CheckTicketButton from "../pieces/CheckTicketButton";

import { GAMES } from "../../constants";

import { AssistantContext } from "../../../helpers/AssistantContext";
import { sendText } from "../../../helpers/Assistant";
import { setPageCommands, sendAction } from "../../../helpers/Assistant";
import { isFullHD, isTouch } from "../../../helpers/ScreenUtils";

import {
  CheckOfflineTicketFormParagraphText1,
  CheckOfflineTicketImg,
  CheckOfflineTicketContainer,
  CheckOfflineTicketGoToLottery,
  CheckOfflineTicketTextfield,
} from "./styles/offline-tickets-styles";

class MyTickets extends React.Component {
  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    this.state = {
      gameSetting: "",
      editionNumber: "",
      ticketNumberFieldValues: "",
    };
    //this.initialFocus=null
  }
  //VOICE_CHECK_OFFLINE

  getGameSettings(gameId) {
    return GAMES.find(function (game, index) {
      if (game.id === gameId) {
        return game;
      }
    });
  }

  componentDidMount() {
    this.initialFocus?.focus();
    setPageCommands(this.context, {
      VOICE_CHECK_OFFLINE: () => {
        if (this.state.editionNumber && this.state.ticketNumberFieldValues) {
          sendAction(this.context, {
            action_id: "CHECK_TICKET",
            parameters: {
              draw: this.state.editionNumber,
              numbers: this.state.ticketNumberFieldValues,
            },
          });
        }
      },
      NULL: () => {},
    });
  }

  getWidth() {
    if (detectDevice() == "mobile") {
      return "312px";
    }
    return "536px";
  }

  render() {
    return (
      <>
        <StolotoTabs
          messagePopupWasOpened={this.props.messagePopupWasOpened}
          activeTab="check_tickets"
          messageShow={this.props.messageShow}
        />

        <CheckOfflineTicketContainer>
          <Row
            style={{
              justifyContent: "center",
              margin: "auto",
              width: this.getWidth(),
            }}
          >
            <Col>
              <Card style={{ background: "#000000", width: this.getWidth() }}>
                <Container
                  style={{
                    marginBottom: isFullHD() ? "1.5em" : "0.5em",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CheckOfflineTicketImg
                    src={
                      process.env.PUBLIC_URL +
                      "/mini/" +
                      this.getGameSettings(this.props.gameInfo.game_id)
                        ?.gameLogo
                    }
                  />
                </Container>
                <CheckOfflineTicketTextfield
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && !isTouch()) {
                      setTimeout(() => {
                        document
                          .getElementById(`text_field_check_offline_tickets`)
                          .focus();
                      }, 500);
                    }
                  }}
                  ref={(but) => {
                    this.initialFocus = but;
                  }}
                  size={isFullHD() ? "l" : "xs"}
                  tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                  key={2}
                  placeholder="Номер тиража"
                  onInput={(e) => {
                    this.setState({ editionNumber: e.target.value });
                  }}
                  type="number"
                />

                <CheckOfflineTicketTextfield
                  id="text_field_check_offline_tickets"
                  size={isFullHD() ? "l" : "xs"}
                  tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                  style={{ marginTop: "20px" }}
                  key={1}
                  placeholder="Номер билета"
                  onInput={(e) => {
                    this.setState({ ticketNumberFieldValues: e.target.value });
                  }}
                  type="number"
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && !isTouch()) {
                      setTimeout(() => {
                        document
                          .getElementById(`check_ticket_button_offline`)
                          .focus();
                      }, 500);
                    }
                  }}
                />
                <CheckOfflineTicketFormParagraphText1
                  visible={this.props?.gameInfo?.error != undefined}
                >
                  {"Ошибка ввода данных"}
                </CheckOfflineTicketFormParagraphText1>
                <CheckTicketButton
                  id_button="check_ticket_button_offline"
                  tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                  disableButton={
                    this.state.editionNumber == "" ||
                    this.state.ticketNumberFieldValues == ""
                  }
                  editionNumber={this.state.editionNumber}
                  numbers={this.state.ticketNumberFieldValues}
                  buttonText={"Проверить"}
                />
                <CheckOfflineTicketGoToLottery
                  size={isFullHD() ? "l" : "s"}
                  scaleOnInteraction={false}
                  tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                  onClick={() => {
                    sendText(this.context, "назад");
                  }}
                  text={"Выбрать лотерею"}
                />
              </Card>
            </Col>
          </Row>
        </CheckOfflineTicketContainer>
      </>
    );
  }
}

export default MyTickets;
