import { detectDevice } from "@sberdevices/plasma-ui";
import { isTV } from "@sberdevices/plasma-ui/utils/deviceDetection";

export const isTouch = () => {
  return detectDevice() === "sberPortal" || detectDevice() === "mobile";
};

export const isSmallScreen = () => {
  const screenHeight = window.screen.height;
  return detectDevice() === "sberPortal" || (isTV() && screenHeight < 900);
};

export const isFullHD = () => {
  const screenHeight = window.screen.height;
  return detectDevice() === "sberBox" || (isTV() && screenHeight >= 900);
};

export const detectDeviceSize = () => {
  if (isFullHD()) {
    return "sberBox";
  }
  if (isSmallScreen()) {
    return "sberPortal";
  }
  return "mobile";
};
