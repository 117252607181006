import React from "react";

import {
  Container,
  Button,
  CarouselItem,
  detectDevice,
} from "@sberdevices/plasma-ui";

import { StolotoTabs } from "../StolotoTabs";
import EditionActions from "../common/EditionActions";
import ButtonsActions from "../common/ButtonsActions";
import LottoTicketsController from "./ticket/LottoTicketsController";

import { RULES_TOP3, GAMES } from "../constants";

import { setPageCommands } from "../../helpers/Assistant";
import { formatMoney } from "../../helpers/TextUtils";
import { AssistantContext } from "../../helpers/AssistantContext";
import {
  isFullHD,
  isSmallScreen,
  isTouch,
  detectDeviceSize,
} from "../../helpers/ScreenUtils";

import { StyledCarouselCard } from "../common/styles/common-elements-styles";
import {
  ControlTicketFormRow,
  ControlTicketFormStyledCarouselItem,
} from "./styles/lotto-styles";
import {
  LeftContainer,
  RightContainer,
  LeftShadow,
  RightShadow,
  StyledCarousel,
  ButtonsRow,
  MainContainer,
} from "../../style/style";

class ControlTicketForm extends React.Component {
  static contextType = AssistantContext;
  constructor(props) {
    super(props);
    this.state = {
      screenMobile: window.screen.width < 450 ? true : false,
      tickets: this.props.tickets,
      gameName: this.props.gameName,
      carouselIndex: detectDevice() !== "mobile" ? 0 : 1,
      editionPopupOpen: false,
      currentDrawNumber: this.props.gameInfo.draw.number,
      gameInfo: this.props.gameInfo,
      paddingStartCarousel: "50%",
      payPopupWasOpened: false,
      activeDrawInfo: this.getDrawData(this.props.gameInfo)[0],
    };

    //this.myRef = React.createRef();
    //this.nameInput = null
  }

  payPopupClosed = () => {
    this.setState({ payPopupWasOpened: false });
  };

  componentDidMount() {
    // if(this.props.ticketWaiting){
    //     this.setPageOverflow('hidden')
    // }else{
    this.setPageOverflow("scroll");

    // }

    setPageCommands(this.context, {
      CLEAR_TICKETS: (action) => {
        this.clearTicket();
      },
      // DELETE_TICKETS: (action) => {
      //     this.deleteTickets()
      // },
      GENERATE_NUMBERS: (action) => {
        this.getRandomNumbers();
      },
      SHOW_DRAWS_POPUP: () => {
        let e = document.createEvent("HTMLEvents");
        e.initEvent("keyup", true, true);
        e.keyCode = 13;
        //e.target = document.getElementById('open_pay_papup_button');
        document.getElementById("open_draws_papup_button").dispatchEvent(e);
        document.getElementById("open_draws_papup_button").click();
      },
      FINISH_PAY: () => {
        let e = document.createEvent("HTMLEvents");
        e.initEvent("keyup", true, true);
        e.keyCode = 13;
        console.log("I AM IN START PAY2");
        if (document.getElementById("pay_popup_button") != null) {
          document.getElementById("pay_popup_button").dispatchEvent(e);
          document.getElementById("pay_popup_button").click();
        }
      },
      START_PAY: () => {
        this.setState({ payPopupWasOpened: true });
        // let e = document.createEvent("HTMLEvents");
        // e.initEvent("keyup", true, true);
        // e.keyCode = 13;

        //     if(document.getElementById('open_pay_papup_button')!=null && !document.getElementById('open_pay_papup_button').disabled){
        //         document.getElementById('open_pay_papup_button').dispatchEvent(e)
        //         document.getElementById('open_pay_papup_button').click()
        //     }
      },
      NULL: () => {},
    });

    if (!isTouch()) {
      //  if(['keno2','top3'].includes(this.props.gameName)){
      this.setState({ gameName: this.props.gameName });
      if (!this.props?.ticketWaiting) {
        setTimeout(() => {
          document.getElementById(`focus_1_mainTicket_keyTicket_0`).focus();
        }, 700);
        setTimeout(() => {
          this.initKeyboardEvent();
        }, 1000);
      }

      //  }
    }
  }
  getDisplayStyle = () => {
    console.log(window.payPopupWasOpened);
    return window.payPopupWasOpened;
  };
  componentWillUnmount() {
    setPageCommands(this.context, {});

    this.setPageOverflow("hidden");
  }

  setPageOverflow(overflowValue) {
    if (detectDevice() == "mobile") {
      document.getElementsByTagName("body")[0].style.overflowY = overflowValue;
      document.getElementsByTagName("html")[0].style.overflowY = overflowValue;
      document.body.scrollTo(0, window.pageXOffset);
    }
  }
  closePayPopup = () => {
    this.setState({ openPayPopup: false });
  };
  getRandomNumbers() {
    if (["top3", "keno2"].includes(this.props.gameName)) {
      if (detectDevice() == "mobile") {
        this.props.setRandomNumbers(this.state.carouselIndex - 1);
      } else {
        this.props.setRandomNumbers(this.state.carouselIndex);
      }
    } else {
      if (detectDevice() == "mobile") {
        this.props.setRandomNumbers(
          this.state.carouselIndex - 1,
          this.props.gameName
        );
      } else {
        this.props.setRandomNumbers(
          this.state.carouselIndex,
          this.props.gameName
        );
      }
    }
  }

  deleteTickets() {
    this.props.removeTicket(this.state.carouselIndex);
    //this.setState({tickets: []})
    if (
      this.state.carouselIndex == this.props.tickets.length &&
      this.state.carouselIndex - 1 > 0
    ) {
      this.onFocusTicket(this.state.carouselIndex - 1);
      if (!isTouch()) {
        setTimeout(() => {
          document
            .getElementById(
              `focus_1_mainTicket_keyTicket_${this.state.carouselIndex}`
            )
            .focus();
        }, 800);
        setTimeout(() => {
          this.initKeyboardEvent();
        }, 1000);
      }
    }
    if (this.props.tickets.length == 1) {
      if (!isTouch()) {
        this.onFocusTicket(0);
        setTimeout(() => {
          document.getElementById(`focus_1_mainTicket_keyTicket_0`).focus();
        }, 500);
        setTimeout(() => {
          this.initKeyboardEvent();
        }, 700);
      }
    }
  }

  clearTicket() {
    if (["top3", "keno2"].includes(this.props.gameName)) {
      if (detectDevice() == "mobile") {
        this.props.clearTicket(this.state.carouselIndex - 1);
      } else {
        this.props.clearTicket(this.state.carouselIndex);
      }
    } else {
      if (detectDevice() == "mobile") {
        this.props.clearTicket(this.state.carouselIndex - 1);
      } else {
        this.props.clearTicket(this.state.carouselIndex);
      }
    }
  }

  getTop3Numbers(ticket) {
    let currentTicket =
      this.props.tickets[ticket]["selectedNumbersFirstTicket"];
    let numbers = [];
    currentTicket.forEach((curTicket, key) => {
      numbers.push(
        key +
          1 +
          curTicket.rowNumber *
            RULES_TOP3[this.props.tickets[ticket].gameSetting]["firstTicket"][
              "cols"
            ]
      );
    });
    return numbers;
  }

  getGameSettings(gameId) {
    return GAMES.find(function (game, index) {
      if (game.id === gameId) {
        return game;
      }
    });
  }

  initKeyboardEvent() {
    var keyboardEvent = document.createEvent("KeyboardEvent");
    var initMethod =
      typeof keyboardEvent.initKeyboardEvent !== "undefined"
        ? "initKeyboardEvent"
        : "initKeyEvent";

    keyboardEvent[initMethod](
      "keydown", // event type: keydown, keyup, keypress
      true, // bubbles
      true, // cancelable
      window, // view: should be window
      false, // ctrlKey
      false, // altKey
      false, // shiftKey
      false, // metaKey
      40, // keyCode: unsigned long - the virtual key code, else 0
      0 // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
    );
    document.dispatchEvent(keyboardEvent);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.gameName != nextProps.gameName) {
      console.log(nextProps);
      this.setState({
        gameName: nextProps.gameName,
        currentDrawNumber: nextProps.gameInfo.draw.number,
        gameInfo: nextProps.gameInfo,
      });
      //this.forceUpdate()

      if (
        !["keno2"].includes(nextProps.gameName) &&
        this.state.tickets.length == 1 &&
        detectDevice() == "mobile"
      ) {
        this.onFocusTicket(1);
      }
      if (!isTouch()) {
        //this.onFocusTicket(0)
        // if(!['keno2','top3'].includes(nextProps.gameName)){
        setTimeout(() => {
          document.getElementById(`focus_1_mainTicket_keyTicket_0`).focus();
        }, 1000);
        setTimeout(() => {
          this.initKeyboardEvent();
        }, 1500);
        // }
      }
      //фиксит дёргание при переходах с лотереи на лотерею голосом
      if (!["keno2"].includes(nextProps.gameName) && isSmallScreen()) {
        this.onFocusTicket(0);
      }
    }
  }
  getActiveButton(editionInfo) {
    return this.getActionsButton(editionInfo);
  }

  getBackButton() {
    return <Button> Назад </Button>;
  }

  addNewTicket = () => {
    if (this.props.tickets.length < 5) {
      this.props.addNewTicket();
      if (detectDevice() !== "mobile") {
        this.onFocusTicket(this.props.tickets.length - 1);
      } else {
        this.onFocusTicket(this.props.tickets.length);
      }
      if (!isTouch()) {
        setTimeout(() => {
          document
            .getElementById(
              `focus_1_mainTicket_keyTicket_${this.props.tickets.length - 1}`
            )
            .focus();
        }, 700);
        setTimeout(() => {
          this.initKeyboardEvent();
        }, 1000);
      }
    }
  };
  getActionsButton(editionInfo) {
    return (
      <ButtonsActions
        messagePopupWasOpenedFunction={this.props.messagePopupWasOpenedFunction}
        messagePopupWasOpened={this.props.messagePopupWasOpened}
        ticketsInCarousel={this.props.tickets.length}
        tickets={this.props.tickets}
        messageShow={this.props.messageShow || this.state.editionPopupOpen}
        waitingPage={this.props?.waitResult}
        gameInfo={this.props.gameInfo}
        ticketsQty={this.props.ticketQty}
        paySum={this.props.paySum}
        betCost={this.props.gameInfo.draw.betCost}
        actionButton={this.addNewTicket}
        editionInfo={editionInfo}
        activeDrawInfo={this.state.activeDrawInfo}
        currentDrawNumber={this.state.currentDrawNumber}
        payPopupWasOpened={this.state.payPopupWasOpened}
        payPopupClosed={this.payPopupClosed}
      />
    );
  }

  editDrawNumber = (currentDrawNumber) => {
    this.setState({ currentDrawNumber: currentDrawNumber });
  };

  openEditionPopup = () => {
    if (this.state.editionPopupOpen) {
      this.setState({ editionPopupOpen: false });
    } else {
      this.setState({ editionPopupOpen: true });
    }
  };

  leftArrowPress = (e, key) => {
    if (key != 0) {
      if (this.props.gameName != "keno2") {
        this.setState({ carouselIndex: key - 1 });
      } else {
        this.setState({ carouselIndex: key - 1 });
        this.props.setTicketCarouselIndex(key - 1);
      }
      setTimeout(() => {
        document
          .getElementById(`focus_1_mainTicket_keyTicket_${key - 1}`)
          .focus();
      }, 700);
      setTimeout(() => {
        this.initKeyboardEvent();
      }, 1000);
    }
  };

  onFocusTicket = (ind) => {
    if (this.props.gameName != "keno2") {
      if (ind == 1 && detectDevice() == "mobile") {
        this.setState({ carouselIndex: 2 });
      }
      if (ind != this.state.carouselIndex || ind == 0) {
        this.setState({ carouselIndex: ind });
      }
    } else {
      if (ind == 1 && detectDevice() == "mobile") {
        this.props.setTicketCarouselIndex(2);
      }

      if (ind != this.state.carouselIndex || ind == 0) {
        this.props.setTicketCarouselIndex(ind);
      }
    }
  };

  getIndexOnPressButton = (key) => {
    if (typeof key != "undefined" || key != "undefined" || key != "") {
      console.log("not undefined: " + key);
    } else {
      console.log("undefined" + key);
    }
  };

  setActiveDraw = (activeDrawInfo) => {
    this.setState({ activeDrawInfo });
  };

  rightArrowPress = (e, key) => {
    if (key != this.state.tickets.length - 1) {
      if (this.props.gameName != "keno2") {
        this.setState({ carouselIndex: key + 1 });
      } else {
        this.setState({ carouselIndex: key + 1 });
        this.props.setTicketCarouselIndex(key + 1);
      }
      setTimeout(() => {
        document
          .getElementById(`focus_1_mainTicket_keyTicket_${key + 1}`)
          .focus();
      }, 700);
      setTimeout(() => {
        this.initKeyboardEvent();
      }, 1000);
    }
  };

  getOneRowCarousel() {
    let editionInfo = this.getDrawData();
    const styles =
      this.props.tickets.length > 1
        ? null
        : { display: "flex", justifyContent: "space-around" };
    return (
      <>
        <Container
          style={{ display: "flex", alignItems: "center", marginTop: "65px" }}
        >
          <EditionActions
            messagePopupWasOpenedFunction={
              this.props.messagePopupWasOpenedFunction
            }
            messagePopupWasOpened={this.props.messagePopupWasOpened}
            editionInfo={editionInfo}
            activeDrawInfo={this.state.activeDrawInfo}
            drawInfo={this.state.gameInfo}
            gameName={this.state.gameInfo.name}
            drawSuperprise={this.state.gameInfo.draw.superPrize}
            drawNumber={this.state.gameInfo.draw.number}
            drawId={this.state.gameInfo.draw.id}
            drawDate={this.state.gameInfo.draw.date}
            tickets={this.state.tickets}
            editDrawNumber={this.editDrawNumber}
            messageShow={this.props.messageShow || this.state.editionPopupOpen}
            openEditionPopup={this.openEditionPopup}
            ticketWaiting={this.props?.ticketWaiting}
            updateActiveDraw={(activeDraw) => {
              this.setActiveDraw(activeDraw);
            }}
          />
          <Container
            style={{
              paddingRight: "0px",
              paddingLeft: "0px",
              top: "0em",
              marginBottom: "15em",
            }}
          >
            <StyledCarousel
              detectActive={
                !["top3", "keno2"].includes(this.props.gameName) ? true : false
              }
              detectThreshold={0.9}
              scrollSnapType="mandatory"
              scrollAlign="center"
              scrollSnapAlign="center"
              paddingStart={
                !["top3", "keno2"].includes(this.props.gameName) && "50%"
              }
              paddingEnd={
                !["top3", "keno2"].includes(this.props.gameName) && "50%"
              }
              axis="x"
              animatedScrollByIndex={true}
              onIndexChange={(index) => this.onIndexChangeActivity(index)}
              index={
                this.props.hasOwnProperty("ticketCarouselIndex")
                  ? this.props.ticketCarouselIndex
                  : this.state.carouselIndex
              }
              style={{
                display:
                  ["top3", "keno2"].includes(this.props.gameName) && "grid",
                justifyItems:
                  ["top3", "keno2"].includes(this.props.gameName) && "center",
              }}
            >
              {!["top3", "keno2"].includes(this.props.gameName) && (
                <ControlTicketFormStyledCarouselItem
                  onFocus={() => {
                    console.log("i am on focus");
                  }}
                  style={{
                    visibility: "hidden",
                    minWidth: "1px",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  <StyledCarouselCard moreThenOneTicket={false}>
                    <div></div>
                  </StyledCarouselCard>
                </ControlTicketFormStyledCarouselItem>
              )}

              {this.props.tickets.map((ticket, key) => (
                <ControlTicketFormStyledCarouselItem id={key} key={key}>
                  <StyledCarouselCard
                    key={key}
                    id={key}
                    moreThenOneTicket={true}
                  >
                    <LottoTicketsController
                      waitResult={this.props.ticketWaiting}
                      messageShow={
                        this.props.messageShow || this.state.editionPopupOpen
                      }
                      keyIndex={key}
                      ticketQty={this.props.tickets.length}
                      key={key}
                      setTicketNumber={this.props.setTicketNumber}
                      gameName={this.props.gameInfo.name}
                      ticketInfo={ticket}
                      rules={
                        this.props.gameInfo.name != "top3"
                          ? this.props.rules
                          : this.props.top3RulesList[ticket.gameSetting]
                      }
                      rowsFirstPlayingField={
                        this.props.gameInfo.name != "top3"
                          ? this.props.rowsFirstPlayingField
                          : this.props.top3RulesList[ticket.gameSetting][
                              "firstTicket"
                            ]["rows"]
                      }
                      colsFirstPlayingField={
                        this.props.gameInfo.name != "top3"
                          ? this.props.colsFirstPlayingField
                          : this.props.top3RulesList[ticket.gameSetting][
                              "firstTicket"
                            ]["cols"]
                      }
                      maxSquareViewedFirstPlayingField={
                        this.props.gameInfo.name != "top3"
                          ? this.props.maxSquareViewedFirstPlayingField
                          : this.props.top3RulesList[ticket.gameSetting][
                              "firstTicket"
                            ]["maxSquareViewed"]
                      }
                      selectedNumbersFirstPlayingField={
                        this.props.gameInfo.name != "top3"
                          ? ticket?.secondGame ||
                            ticket["selectedNumbersFirstTicket"]
                          : this.getTop3Numbers(this.props.selectedTicketKey)
                      }
                      rowsSecondPlayingField={this.props.rowsSecondPlayingField}
                      colsSecondPlayingField={this.props.colsSecondPlayingField}
                      maxSquareViewedSecondPlayingField={
                        this.props.maxSquareViewedSecondPlayingField
                      }
                      selectedNumbersSecondPlayingField={
                        ticket?.threeGame ||
                        ticket["selectedNumbersSecondTicket"]
                      }
                      setRandomNumbers={this.props.setRandomNumbers}
                      gameSetting={ticket?.gameSetting}
                      editTicketRule={this.props.editTicketRule}
                      setFirstGameChoise={this.props.setFirstGameChoise}
                      top3RulesList={this.props.top3RulesList}
                      ticketWaiting={this.props?.ticketWaiting}
                      setThreeGameChoise={this.props.setThreeGameChoise}
                      leftArrowPress={this.leftArrowPress}
                      rightArrowPress={this.rightArrowPress}
                      setThreeGameEmpty={this.props.setThreeGameEmpty}
                      showPayText={this.props.paySum > 0}
                      payMoney={formatMoney(this.props.paySum)}
                    />
                  </StyledCarouselCard>
                </ControlTicketFormStyledCarouselItem>
              ))}
            </StyledCarousel>

            {this.getActiveButton(editionInfo)}
          </Container>
        </Container>
      </>
    );
  }

  addActiveTicketBarcode = (tickets) => {
    this.setState({ tickets: tickets });
  };

  onIndexChangeActivity(index) {
    if (this.props.gameName != "keno2") {
      this.setState({ carouselIndex: index });
    } else {
      this.setState({ carouselIndex: index });
      this.props.setTicketCarouselIndex(index);
    }
  }

  getWidthOfTicketCarouselItem() {
    const stylesMinWidth = { sberBox: "33.5em" };
    if (this.props.gameInfo.name != "top3") {
      if (isFullHD()) {
        if (["1224", "zabava"].includes(this.props.gameName)) {
          return "27.3em";
        }
        if (
          ["duel", "4x20", "oxota", "dvazhdydva"].includes(this.props.gameName)
        ) {
          return "20.4em";
        }
        if (
          ["rapido", "rapido2", "rapido-drive", "rapido-ultra"].includes(
            this.props.gameName
          )
        ) {
          return "23em";
        }
      }
      if (stylesMinWidth.hasOwnProperty(detectDeviceSize())) {
        return stylesMinWidth[detectDeviceSize()];
      }
    } else {
      if (isSmallScreen()) {
        return this.props.gameName == "top3"
          ? "19em"
          : this.props.gameName == "1224"
          ? "23em"
          : "20em";
      }
      if (isFullHD()) {
        return this.props.gameName == "top3" ? "30.5em" : "30.5em";
      }
    }
  }
  getMarginTopRightContent() {
    if (isSmallScreen()) {
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "106px";
      }
      return "15px";
    }
    if (isFullHD()) {
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "56px";
      }
    }
  }
  getMarginRightTicket() {
    if (isFullHD()) {
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "8px";
      }
    }
    if (isSmallScreen()) {
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "8px";
      }
      return "8px";
    }
    return "8px";
  }

  getCarouselItemStyles(isInvisible) {
    return {
      outline: "none",
      borderColor: "inherit",
      width: "100%",
      minWidth: this.getWidthOfTicketCarouselItem(),
      marginRight: this.getMarginRightTicket(),
      display: "flex",
      flexDirection: "column",
      maxWidth: this.props.gameName == "keno2" && isSmallScreen() && "22em",
    };
  }

  getDrawData(gameInfo) {
    if (!gameInfo) {
      gameInfo = this.state.gameInfo;
    }
    let drawDataList = [];
    if (
      new Date(gameInfo.draw.stopSalesDate) > new Date() ||
      this.props.ticketWaiting
    ) {
      drawDataList.push({
        drawSuperprise: gameInfo.draw.superPrize,
        drawNumber: gameInfo.draw.number,
        drawId: gameInfo.draw.id,
        drawDate: gameInfo.draw.stopSalesDate,
      });
    }
    if (
      gameInfo.nextDraw &&
      new Date(gameInfo.nextDraw.stopSalesDate) > new Date()
    ) {
      drawDataList.push({
        drawSuperprise: gameInfo.nextDraw.superPrize,
        drawNumber: gameInfo.nextDraw.number,
        drawId: gameInfo.nextDraw.id,
        drawDate: gameInfo.nextDraw.stopSalesDate,
      });
    }
    if (gameInfo.specialDraws != null) {
      gameInfo.specialDraws.map((curDraw, i) =>
        drawDataList.push({
          drawSuperprise: curDraw.superPrize,
          drawNumber: curDraw.number,
          drawId: curDraw.id,
          drawDate: curDraw.stopSalesDate,
        })
      );
    }

    return drawDataList;
  }
  getPaddingEndDoubleRow() {
    return "50%";
  }
  getPaddingStartDoubleRow() {
    return "50%";
  }
  getDoubleRowCarousel() {
    const paddingStart = {
      sberPortal: "40px",
      sberBox: "180px",
      mobile: "1.5em",
    };
    let editionInfo = this.getDrawData();

    return (
      <div style={{ display: "contents", marginTop: "2em" }}>
        <LeftContainer>
          <EditionActions
            messagePopupWasOpened={this.props.messagePopupWasOpened}
            messagePopupWasOpenedFunction={
              this.props.messagePopupWasOpenedFunction
            }
            editionInfo={editionInfo}
            activeDrawInfo={this.state.activeDrawInfo}
            drawInfo={this.state.gameInfo}
            gameName={this.state.gameInfo.name}
            drawSuperprise={this.state.gameInfo.draw.superPrize}
            drawNumber={this.state.gameInfo.draw.number}
            drawId={this.state.gameInfo.draw.id}
            drawDate={this.state.gameInfo.draw.date}
            tickets={this.props.tickets}
            editDrawNumber={this.editDrawNumber}
            messageShow={this.props.messageShow || this.state.editionPopupOpen}
            ticketWaiting={this.props?.ticketWaiting}
            openEditionPopup={this.openEditionPopup}
            updateActiveDraw={(activeDraw) => {
              this.setActiveDraw(activeDraw);
            }}
          />
          <ButtonsRow>{this.getActiveButton(editionInfo)}</ButtonsRow>
        </LeftContainer>
        <LeftShadow></LeftShadow>
        <RightContainer specialMargin={this.getMarginTopRightContent()}>
          <StyledCarousel
            id="styled_carousel_Box"
            //animatedScrollByIndex={true}
            axis="x"
            detectActive={true}
            scrollSnapType="mandatory"
            scrollSnapAlign="center"
            detectThreshold={0.9}
            index={
              this.props.hasOwnProperty("ticketCarouselIndex")
                ? this.props.ticketCarouselIndex
                : this.state.carouselIndex
            }
            onIndexChange={(index) =>
              isTouch() && this.onIndexChangeActivity(index)
            }
            //animatedScrollByIndex={true}
            //throttleMs={1000}
            paddingStart={isFullHD() ? "50px" : "90px"}
            //paddingEnd={'50%'}
            scrollAlign={"center"}
            tabIndex={-1}
          >
            {this.returnCarouselItems()}
          </StyledCarousel>
        </RightContainer>
        <RightShadow></RightShadow>
      </div>
    );
  }
  returnCarouselItems() {
    let itemsCarousel = [];

    this.props.tickets.map((ticket, ind) =>
      itemsCarousel.push(
        <CarouselItem
          tabIndex={-1}
          style={this.getCarouselItemStyles()}
          key={ind}
        >
          <StyledCarouselCard
            tabIndex={-1}
            id={ind}
            key={ind}
            onFocus={(e) => {
              this.onFocusTicket(ind);
            }}
          >
            <LottoTicketsController
              messagePopupWasOpened={this.props.messagePopupWasOpened}
              waitResult={this.props.ticketWaiting}
              messageShow={
                this.props.messageShow || this.state.editionPopupOpen
              }
              keyIndex={ind}
              setTicketNumber={this.props.setTicketNumber}
              gameName={this.props.gameInfo.name}
              ticketInfo={ticket}
              rules={
                this.props.gameInfo.name != "top3"
                  ? this.props.rules
                  : this.props.top3RulesList[ticket.gameSetting]
              }
              rowsFirstPlayingField={
                this.props.gameInfo.name != "top3"
                  ? this.props.rowsFirstPlayingField
                  : this.props.top3RulesList[ticket.gameSetting]["firstTicket"][
                      "rows"
                    ]
              }
              colsFirstPlayingField={
                this.props.gameInfo.name != "top3"
                  ? this.props.colsFirstPlayingField
                  : this.props.top3RulesList[ticket.gameSetting]["firstTicket"][
                      "cols"
                    ]
              }
              maxSquareViewedFirstPlayingField={
                this.props.gameInfo.name != "top3"
                  ? this.props.maxSquareViewedFirstPlayingField
                  : this.props.top3RulesList[ticket.gameSetting]["firstTicket"][
                      "maxSquareViewed"
                    ]
              }
              selectedNumbersFirstPlayingField={
                this.props.gameInfo.name != "top3"
                  ? ticket?.secondGame || ticket["selectedNumbersFirstTicket"]
                  : this.getTop3Numbers(this.props.selectedTicketKey)
              }
              rowsSecondPlayingField={this.props.rowsSecondPlayingField}
              colsSecondPlayingField={this.props.colsSecondPlayingField}
              maxSquareViewedSecondPlayingField={
                this.props.maxSquareViewedSecondPlayingField
              }
              selectedNumbersSecondPlayingField={
                ticket?.threeGame || ticket["selectedNumbersSecondTicket"]
              }
              setRandomNumbers={this.props.setRandomNumbers}
              gameSetting={ticket?.gameSetting}
              editTicketRule={this.props.editTicketRule}
              setFirstGameChoise={this.props.setFirstGameChoise}
              top3RulesList={this.props.top3RulesList}
              ticketWaiting={this.props?.ticketWaiting}
              setThreeGameChoise={this.props.setThreeGameChoise}
              leftArrowPress={this.leftArrowPress}
              rightArrowPress={this.rightArrowPress}
              getIndexOnPressButton={this.getIndexOnPressButton}
            />
          </StyledCarouselCard>
        </CarouselItem>
      )
    );
    return itemsCarousel;
  }
  getCarousel() {
    return !this.state.screenMobile
      ? this.getDoubleRowCarousel()
      : this.getOneRowCarousel();
  }
  getTopMarginOfTicketForm() {
    if (isFullHD()) {
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "15%";
      }
      if (!["top3", "keno2"].includes(this.props.gameName)) {
        return "10%";
      }
      return "10%";
    }
    if (isSmallScreen()) {
      if (["1224", "zabava"].includes(this.props.gameName)) {
        return "7%";
      }
      if (!["top3", "keno2"].includes(this.props.gameName)) {
        return "7%";
      }
      return "7.5%";
    }
  }
  render() {
    return (
      <>
        <StolotoTabs
          waitingPage={false}
          messagePopupWasOpened={this.props.messagePopupWasOpened}
          messageShow={this.props.messageShow || this.state.editionPopupOpen}
          activeTab="lotteries"
          showPayText={this.props.paySum > 0}
          payMoney={formatMoney(this.props.paySum)}
        />

        <MainContainer>
          <ControlTicketFormRow
            className="loto-wrapper"
            makeTopMargin={this.getTopMarginOfTicketForm()}
          >
            {this.getCarousel()}
          </ControlTicketFormRow>
        </MainContainer>
      </>
    );
  }
}

export default ControlTicketForm;
