import styled from "styled-components";

import { Card, TextBoxTitle, Col, Button } from "@sberdevices/plasma-ui";

export const MainMessageContainer = styled(Card)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: grid;
  background-color: rgba(0, 0, 0, 0.6);
  grid-template-areas:
    "content img"
    "keyboard img";
  text-align: center;
  justify-content: center;
  grid-area: content;
  align-content: center;
`;

export const MainImg = styled.div`
  grid-area: img;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ImgQr = styled.img`
  width: 200px;
`;

export const MessagesContentCol = styled(Col)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    padding-right: 20px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    padding-right: 20px;
  }
`;

export const TextBoxTitleMessages = styled(TextBoxTitle)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 28px;
    font-weight: 600;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    font-size: 28px;
    font-weight: 600;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const MainContent = styled(Card)`
  grid-area: content;
  display: flex;

  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1a1a1a;
  z-index: 10;
  border-radius: 20px;
  @media (min-width: 1281px) and (max-width: 1961px) {
    height: 100%;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    height: 100%;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    height: 100%;
    width: 90%;
    margin: auto;
  }
`;

export const MessagesButton = styled(Button)`
  @media (min-width: 1281px) and (max-width: 1961px) {
    min-width: 225px;
  }
  @media (min-width: 961px) and (max-width: 1281px) {
    min-width: 150px;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    min-width: 100px;
  }
`;

export const MainKeyboard = styled.div`
  grid-area: keyboard;
  display: flex;
  height: 100%;
  width: 100%;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
  @media (min-width: 140px) and (max-width: 960px) {
    padding-top: 5px;
    //padding-bottom: 2em;
  }
`;
