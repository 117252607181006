export function formatMoney(input: number | undefined): string {
  if (input === undefined) {
    return "-";
  }
  const numberStr = input.toLocaleString("ru-RU");
  return numberStr + " ₽";
}

export function formatTimeToDraw(drawTimeStr: any, targetTime: any): string {
  if (drawTimeStr === undefined) {
    return "не назначен";
  }
  let drawTime = new Date(targetTime);
  const now = new Date();
  //const delta = Math.round((drawTime.valueOf() - now.valueOf()) / 1000);
  const delta = drawTimeStr;
  const minute = 60,
    hour = minute * 60,
    day = hour * 24;
  if (delta < 0) {
    // check if drawTime is more than 6 months ago,
    if (now.valueOf() - drawTime.valueOf() > 6 * 30 * 24 * 60 * 60 * 1000) {
      return `${drawTime.getDate()} ${
        months[drawTime.getMonth()]
      } ${drawTime.getFullYear()} г.`;
    }
    return `${drawTime.getDate()} ${months[drawTime.getMonth()]}`;
  } else if (delta < minute) {
    return "Через " + `${delta} ${pluralize(delta, "сек.", "сек.", "сек.")}`;
  } else if (delta < hour) {
    const minutes = Math.floor(delta / minute);
    return (
      "Через " + `${minutes} ${pluralize(minutes, "мин.", "мин.", "мин.")}`
    );
  } else if (delta < day) {
    const hours = Math.floor(delta / hour);
    const minutes = Math.floor(delta / minute);

    return minutes % 60 > 0
      ? "Через " +
          `${hours} ${pluralize(hours, "ч.", "ч.", "ч.")}  ${
            minutes % 60
          } ${pluralize(minute, "мин.", "мин.", "мин.")}`
      : "Через " + `${hours} ${pluralize(hours, "ч.", "ч.", "ч.")} `;
  } else {
    return `${drawTime.getDate()} ${months[drawTime.getMonth()]}`;
  }
}

export function pluralize(
  count: number,
  one: string,
  two: string,
  five: string
) {
  count = Math.floor(Math.abs(count)) % 100;
  if (count > 10 && count < 20) {
    return five;
  }
  count = count % 10;
  if (1 === count) {
    return one;
  }
  if (count >= 2 && count <= 4) {
    return two;
  }
  return five;
}

const months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export function getTicketTime(targetTime: any): string {
  if (new Date(targetTime) < new Date()) {
    return "Идёт розыгрыш";
  } else {
    return formatTimeToDraw(-1, targetTime);
  }
}
