import React from "react";

import {
  Cell,
  CellIcon,
  CardBody,
  CardContent,
  TextBox,
  detectDevice,
} from "@sberdevices/plasma-ui";

import { GAMES } from "../../constants";

import { sendAction } from "../../../helpers/Assistant";
import { AssistantContext } from "../../../helpers/AssistantContext";
import { getTicketTime, formatTimeToDraw } from "../../../helpers/TextUtils";
import TimerComponent from "../../../helpers/TimerComponent";
import { isFullHD, isSmallScreen, isTouch } from "../../../helpers/ScreenUtils";
import {
  GamesStyledCard,
  PayPopupImg,
  StyledCarousel,
  StyledCarouselItem,
} from "../../../style/style";
import {
  OnlineTicketsPageTextBoxTitle,
  OnlineTicketsPageTextBoxSubTitle,
  OnlineTicketsPageTextBoxSubTitleS,
  OnlineTicketsTextBoxBigTitle,
} from "./styles/online-tickets-styles";

class OnlineTicketsVerticalCarousel extends React.Component {
  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    this.state = {
      verticalCarouselIndex: 0,
    };
  }

  getGameSettings(gameId) {
    return GAMES.find(function (game, index) {
      if (game.id === gameId) {
        return game;
      }
    });
  }

  checkTicket(assistant, ticket) {
    if (!this.timer) {
      this.props.wasClickedGameCheck();

      sendAction(assistant, {
        action_id: "CHECK_TICKET",
        parameters: {
          draw: ticket.edition,
          numbers: ticket.uniqueId,
          game_id: ticket.gameName,
        },
      });
    }
    this.timer = setTimeout(() => {
      this.timer = undefined;
    }, 1500);
  }

  getPaddingLeftCarouselItem() {
    if (detectDevice() == "mobile") {
      return "0px";
    }
    if (isSmallScreen()) {
      return "0px";
    }
    if (isFullHD()) {
      return "8px";
    }
  }

  getTicketsVerticalCarousel(ticketClassArray, ticketClassName) {
    let tr = [];
    ticketClassArray.sort(
      (firstItem, secondItem) =>
        new Date(secondItem.dateAndTime).valueOf() -
        new Date(firstItem.dateAndTime).valueOf()
    );

    ticketClassArray.map((ticket, key) => {
      tr.push(
        <AssistantContext.Consumer>
          {(assistant) => (
            <StyledCarouselItem
              id={key}
              onFocus={(e) => {
                this.vertical(key);
              }}
              key={key}
              tabIndex={-1}
              scrollSnapAlign="center"
              isZeroPadding={true}
              style={{
                background: "#000000",
                paddingLeft: this.getPaddingLeftCarouselItem(),
                paddingRight: "8px",
              }}
            >
              <GamesStyledCard
                roundness={"12"}
                data-disallowed-directions={
                  key + 1 == ticketClassArray.length && ["down"]
                }
                tabIndex={
                  this.props.messagePopupWasOpened ||
                  !this.props.canJumpToThis ||
                  isTouch(0)
                    ? -1
                    : 0
                }
                onKeyUp={(e) => {
                  if (
                    e.key === "Enter" &&
                    !this.props.checkTicketPressDisabled
                  ) {
                    this.checkTicket(assistant, ticket);
                  }
                }}
                onClick={(e) => {
                  if (isTouch() && !this.props.checkTicketPressDisabled) {
                    this.checkTicket(assistant, ticket);
                  }
                }}
              >
                <CardBody style={{ padding: "0px" }}>
                  <CardContent style={{ padding: "0px" }} compact>
                    <Cell
                      alignLeft={true}
                      alignRight={true}
                      contentLeft={
                        <CellIcon style={{ display: "contents" }}>
                          <PayPopupImg
                            src={
                              process.env.PUBLIC_URL +
                              "/mini/" +
                              this.getGameSettings(ticket.gameName).gameLogo
                            }
                          />
                        </CellIcon>
                      }
                      content={
                        <TextBox style={{ paddingTop: "0px" }}>
                          <OnlineTicketsPageTextBoxTitle>
                            Тираж №{ticket.edition}
                          </OnlineTicketsPageTextBoxTitle>
                          <OnlineTicketsPageTextBoxSubTitle>
                            Билет №{ticket.displayNumber}
                          </OnlineTicketsPageTextBoxSubTitle>
                          {new Date(ticket.dateAndTime) > new Date() ? (
                            <TimerComponent
                              status={ticket.status}
                              myTicketsScreen={true}
                              timerEndReaction={() => this.forceUpdate()}
                              targetTime={ticket.dateAndTime}
                            />
                          ) : ticket.status === "COMPLETED" ? (
                            <OnlineTicketsPageTextBoxSubTitleS
                              myTicketsScreen={true}
                            >
                              {" "}
                              {formatTimeToDraw(-1, ticket.dateAndTime)}
                            </OnlineTicketsPageTextBoxSubTitleS>
                          ) : (
                            <OnlineTicketsPageTextBoxSubTitleS
                              myTicketsScreen={true}
                            >
                              {" "}
                              {getTicketTime(ticket.dateAndTime)}
                            </OnlineTicketsPageTextBoxSubTitleS>
                          )}
                        </TextBox>
                      }
                    />
                  </CardContent>
                </CardBody>
              </GamesStyledCard>
            </StyledCarouselItem>
          )}
        </AssistantContext.Consumer>
      );
    });
    return tr;
  }

  vertical(displayNumber) {
    if (detectDevice() !== "mobile") {
      this.setState({ verticalCarouselIndex: displayNumber });
    }
  }

  getStylesCarousel() {
    if (isFullHD()) {
      return { height: "24em" };
    }
    if (isSmallScreen()) {
      return { height: "12em" };
    }
  }

  onIndexChangeControl(index) {
    if (isTouch()) {
      this.props.editHorisontalIndex(this.props.currentHorisontalIndex);
      this.setState({ verticalCarouselIndex: index });
    }
  }
  render() {
    console.log("i am rendered");
    return (
      <>
        <OnlineTicketsTextBoxBigTitle>
          {this.props.ticketsArray[0].additionGameName != "sportloto"
            ? this.getGameSettings(this.props.ticketsArray[0].gameName).rusName
            : "Спортлото"}
        </OnlineTicketsTextBoxBigTitle>
        <StyledCarousel
          className="asdsa"
          onIndexChange={(index) => this.onIndexChangeControl(index)}
          detectActive={true}
          scrollAlign="center"
          scrollSnapType="mandatory"
          scrollSnapAlign="center"
          axis={"y"}
          index={this.state.verticalCarouselIndex}
          paddingEnd={detectDevice() != "mobile" && "45%"}
          detectThreshold={!isTouch() ? 0.8 : 0.4}
          style={this.getStylesCarousel()}
        >
          {this.getTicketsVerticalCarousel(
            this.props.ticketsArray,
            this.props.ticketClassName
          )}
        </StyledCarousel>
      </>
    );
  }
}

export default OnlineTicketsVerticalCarousel;
