import React from "react";

import { KenoThirdGameTabItem } from "./styles/keno-styles";

class KenoThirdGameCol extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <KenoThirdGameTabItem
          tabIndex={-1}
          waitResult={this.props.waitResult}
          outlined={this.props.outlined}
          isFirstTabInCol={this.props.isFirstTabInCol}
          isActive={this.props.isActive}
          activeCol={this.props.activeCol}
        >
          {this.props.colNumber}
        </KenoThirdGameTabItem>
      </>
    );
  }
}
const KenoThirdGameColMemo = React.memo(KenoThirdGameCol);
export default KenoThirdGameColMemo;
