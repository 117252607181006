import React from "react";

import BingoTicket from "./BingoTicket";

class PreparedTicketConstructor extends React.Component {
  render() {
    return (
      <>
        <BingoTicket
          ticketWaiting={this.props.ticketWaiting}
          touchIndex={this.props.touchIndex}
          buttonColor={this.props.ticketWasSelect ? "black" : "white"}
          bingoName={this.props.gameName}
          ticketBarcode={this.props.ticketInfo.barCode}
          numbers={this.props.ticketInfo.numbers}
        />
      </>
    );
  }
}
export default PreparedTicketConstructor;
